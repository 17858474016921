export const siteBackground = "#eaeff5";

export const fonts = {
    default: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    brand: {
        fontFamily: '"ATGRubrik", sans-serif',
    },
};

export const pageProps = {
    responsibleGamingHeader: {
        height: 34,
    },
};

export const view = {
    fullHightWithoutNav: {
        default: "calc(100vh - 48px)",
        sm: "calc(100vh - 86px)",
    },
};

export const colors = {
    brand: {
        strong: "#1C3885", // a.k.a dark
        default: "#004F9F", // a.k .a main
        subtle: "#006DB6", // a.k.a light
        accent: "#FFDD00",
    },
    text: {
        /*
         *Text color constants from the DS Foundations
         */
        // --text-default
        primary: "#2A3E50", // text default in design foundations
        // --text-subtle
        secondary: "#566473", // text subtle in design foundations
        // --text-subtlest
        subtlest: "#7F8B95",
        // --text-accent
        accent: "#FFDD00",
        // --text-interactive
        interactive: "#097AC8", // Diff with DS #1788D3
        // --text-disabled
        disabled: "#AAB2B9",
        // --text-information
        information: "#004F9F", // Diff with Theme ##014F9F
        // --text-success
        success: "#009A3D",
        // --text-critical
        error: "#D0152D", // Diff with Theme #D0011C
        // --text-attention
        attention: "#F37916",
        // --text-jackpot
        jackpot: "#2A3E50",
        // --text-inverted-default
        invertedDefault: "#fff",
        // --text-inverted-subtle
        invertedSubtle: "#ffffffCC",
        // --text-inverted-disabled
        invertedDisabled: "#ffffff33",
    },
    legacy: {
        gray02: "#1f262c",
        gray05: "#7f8b96",
        gray07: "#e0e2e5",
        gray08: "#ececed",
        gray666: "#666",
        gray999: "#999",
        grayEEE: "#eee",
        greyF5F5F5: "#f5f5f5",
        green: "#44ad52",
        red: "#c5202f",
    },
    common: {
        black: "#000000",
        white: "#ffffff",
        grayscale150: "#151F28",
        grayscale130: "#1D2B38",
        grayscale100: "#2A3E50",
        grayscale090: "#3F5161",
        grayscale080: "#566473",
        grayscale070: "#6A7885",
        grayscale060: "#7F8B95",
        grayscale050: "#949EA7 ",
        grayscale040: "#AAB2B9",
        grayscale030: "#BFC5CB",
        grayscale020: "#D4D8DC",
        grayscale010: "#EAECEF",
        grayscale005: "#F4F5F6",
        // ----------> #334c68
    },

    link: {
        primary: "#097AC8",
        secondary: "#469BD4",
        hover: "#014f9f",
    },
    border: {focused: "#35A1E9"},

    gameType: {
        V75: {
            strong: "#1c3885",
            default: "#014f9f",
            subtle: "#006db6",
        },
        V86: {
            strong: "#5F165E", // Legacy value #711a6f
            default: "#802c7e",
            subtle: "#9e4a9c",
        },
        V64: {
            strong: "#B84809", // Legacy value #ea5b0c
            default: "#ed6c15",
            subtle: "#f68c28",
        },
        V65: {
            strong: "#83061A", // Legacy value #a90821
            default: "#c00a26",
            subtle: "#d43140",
        },
        GS75: {
            strong: "#1A423B",
            default: "#15614E",
            subtle: "#00815C",
        },
        BIG9: {
            strong: "#1c3885",
            default: "#014f9f",
            subtle: "#006db6",
        },
        assorted: {
            strong: "#226491", // Legacy value #2a78ad
            default: "#469BD4", // Legacy value #62ade0
            subtle: "#62ADE0", // Legacy value #78bee9
        },
    },
    alert: {
        info: "#F0F5FA",
        error: "#F9ECEC",
    },
    message: {
        info: "#d9edf7",
    },

    cms: {
        alert: {
            success: "#cceac0",
            info: "#b2cae2",
            warning: "#fbdec0",
            danger: "#f8c0c5",
        },
    },
    modal: {
        legacy: {
            header: {
                background: "#093d76",
            },
            closeButton: {
                background: "#172e65",
            },
        },
    },
    background: {
        gray: "#f5f5f2",
        surface: {
            default: "#ffffff",
            subtle: "#f0f5fa",
        },
    },
    grading: {
        correct: {
            green: "#00762e",
        },
    },
    status: {
        online: {
            background: "#22ac07",
        },
        copy: {
            focused: "#0063b7",
            success: "#d6e9de",
        },
        checked: {},
    },
    input: {
        radio: {
            checked: "#35A1E9",
        },
    },
    tab: {
        hover: {
            background: "#e7e7e7",
        },
    },
    harry: {
        checkbox: {
            border: "#c3d2df",
        },
    },
};

export const receipt = {
    textColor: "#324c69",
    backgroundColor: "#fff6cf",
    borderColor: "#d7d1ba",
    button: {
        textColor: "#534a28",
    },
};

export const chat = {
    systemMessage: {
        news: {
            backgroundColor: "#edf5ff",
            borderColor: "#8eaeeb",
        },
        info: {backgroundColor: "#FDF7E2", borderColor: "#F6D86F"},
    },
    memberButton: {
        backgroundColor: "rgba(0, 109, 182, 0.8)",
    },
};

export const search = {
    input: {
        borderColor: "#bacbdf",
    },
    button: {
        default: {
            gradientStart: colors.common.white,
            gradientEnd: "#dbe4ef",
            gradientHoverStart: "#f7f9fb",
            gradientHoverEnd: "#d2ddeb",
        },
    },
};

export const borderRadius = {
    none: 0,
    subtlest: 2,
    subtle: 4,
    default: 8,
    soft: 16,
    full: 9999,
};

export const shadows = {
    surface: {
        /* The wanted shadow from design doens't work in scroll panels so decreased the blur in the 4th layer from 40px to 8px */
        default:
            "0px 1px 0px 0px rgba(255, 255, 255, 0.04) inset, 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px 0px rgba(0, 0, 0, 0.04), 0px 8px 8px 0px rgba(0, 0, 0, 0.08)",
    },
    paper: {
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.05)",
    },
};
