import { GameStatus } from '@atg-tillsammans-shared/types';
import { GameType } from '@atg-tillsammans-shared/types';
import { RoundState } from '../src/round';
import { RoundBetMethod } from '../src/round';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Amount as scalar. */
  Amount: { input: number; output: number; }
  /** LocalDate as scalar. */
  Date: { input: string; output: string; }
  /** LocalDateTime as scalar. */
  DateTime: { input: string; output: string; }
  /** A JSON scalar */
  JSON: { input: unknown; output: unknown; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
  /** A file part in a multipart request */
  Upload: { input: any; output: any; }
};

export type AddGameInitiatorCandidateInput = {
  key: Scalars['String']['input'];
  teamId: Scalars['ID']['input'];
};

export interface Amounts {
  __typename?: 'Amounts';
  netDividend: Maybe<Scalars['Amount']['output']>;
  payoutFee: Maybe<Scalars['Amount']['output']>;
  sellingFee: Maybe<Scalars['Amount']['output']>;
  sellingFeeRefund: Maybe<Scalars['Amount']['output']>;
  stakeRefund: Maybe<Scalars['Amount']['output']>;
  totalCost: Maybe<Scalars['Amount']['output']>;
  totalPayout: Maybe<Scalars['Amount']['output']>;
  totalRefund: Maybe<Scalars['Amount']['output']>;
}

export interface Application {
  __typename?: 'Application';
  createdDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  member: Maybe<Member>;
  team: Maybe<Team>;
  text: Maybe<Scalars['String']['output']>;
}

export interface BatchBetSummary {
  __typename?: 'BatchBetSummary';
  numberOfCombinations: Maybe<Scalars['Int']['output']>;
  numberOfCoupons: Maybe<Scalars['Int']['output']>;
  numberOfSystems: Maybe<Scalars['Int']['output']>;
  totalCost: Maybe<Scalars['Long']['output']>;
}

export interface BatchBetTeam {
  __typename?: 'BatchBetTeam';
  name: Scalars['String']['output'];
  teamId: Scalars['ID']['output'];
}

export interface BatchBetUploadData {
  __typename?: 'BatchBetUploadData';
  batchBetUploadId: Scalars['String']['output'];
  games: Maybe<Array<Maybe<Game>>>;
  message: Maybe<Scalars['String']['output']>;
  status: BatchBetUploadStatus;
  summary: Maybe<BatchBetSummary>;
}

export enum BatchBetUploadStatus {
  COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE = 'COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE',
  EMPTY_COUPON_LIST = 'EMPTY_COUPON_LIST',
  EMPTY_OR_NULL = 'EMPTY_OR_NULL',
  GAME_TYPE_AND_DATE_DO_NOT_EXIST = 'GAME_TYPE_AND_DATE_DO_NOT_EXIST',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_GAME_TYPE_IN_COUPON = 'INVALID_GAME_TYPE_IN_COUPON',
  INVALID_NR_OF_SYSTEMS = 'INVALID_NR_OF_SYSTEMS',
  MAX_COST_OF_ONE_COUPON_EXCEEDED = 'MAX_COST_OF_ONE_COUPON_EXCEEDED',
  NOT_FOUND = 'NOT_FOUND',
  OK = 'OK',
  REDUCTION_CONDITIONS_HAS_CHANGED = 'REDUCTION_CONDITIONS_HAS_CHANGED',
  XML_VALIDATION_ERROR = 'XML_VALIDATION_ERROR'
}

export interface BetMeta {
  __typename?: 'BetMeta';
  couponId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageRef: Maybe<Scalars['String']['output']>;
}

export type BetMetaIds = {
  couponId: InputMaybe<Scalars['String']['input']>;
  tsn: InputMaybe<Scalars['String']['input']>;
};

export type BetMetaInput = {
  couponId: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageRef: InputMaybe<Scalars['String']['input']>;
};

export interface BetSubscription {
  __typename?: 'BetSubscription';
  id: Scalars['ID']['output'];
  maxAmount: Scalars['Int']['output'];
  numberOfRounds: Maybe<Scalars['Int']['output']>;
  numberOfRoundsLeft: Maybe<Scalars['Int']['output']>;
  selectedRounds: Maybe<Array<Maybe<GameId>>>;
  type: SubscriptionType;
}

export enum BetVertical {
  CASINO = 'CASINO',
  HORSE_BETTING = 'HORSE_BETTING',
  SPORTSBOOK = 'SPORTSBOOK'
}

export interface BetWithDetails {
  __typename?: 'BetWithDetails';
  amounts: Amounts;
  bet: CbsBet;
  betMeta: Maybe<BetMeta>;
  betType: GameType;
  contract: Contract;
  id: Scalars['ID']['output'];
  offering: CbsOffering;
}

export interface CbsBet {
  __typename?: 'CbsBet';
  coupons: Array<CbsCoupon>;
  currency: Maybe<Scalars['String']['output']>;
  grading: Maybe<Grading>;
  numberOfSystems: Scalars['Int']['output'];
  offeringId: Scalars['String']['output'];
  placedAt: Scalars['String']['output'];
  stake: Scalars['Amount']['output'];
  status: CbsBetStatus;
  tsn: Scalars['String']['output'];
  type: Scalars['String']['output'];
  vertical: BetVertical;
}

export enum CbsBetStatus {
  ACTIVE = 'ACTIVE',
  GRADED = 'GRADED',
  UNVERIFIED = 'UNVERIFIED'
}

export interface CbsCoupon {
  __typename?: 'CbsCoupon';
  id: Scalars['String']['output'];
  numberOfCombinations: Maybe<Scalars['Int']['output']>;
  numberOfSystems: Maybe<Scalars['Int']['output']>;
  selections: Scalars['JSON']['output'];
  stake: Scalars['Amount']['output'];
  stakePerCombination: Maybe<Scalars['Float']['output']>;
  unitsPerCombination: Maybe<Scalars['Float']['output']>;
}

export interface CbsOffering {
  __typename?: 'CbsOffering';
  status: OfferingStatus;
  summary: OfferingSummary;
  unitStake: Scalars['Long']['output'];
}

export interface Contract {
  __typename?: 'Contract';
  maxCost: Maybe<Scalars['Amount']['output']>;
  offeringId: Scalars['String']['output'];
  options: Maybe<Scalars['JSON']['output']>;
  owner: Maybe<Scalars['JSON']['output']>;
  stake: Maybe<Scalars['Amount']['output']>;
  type: ContractType;
}

export enum ContractType {
  BET = 'BET',
  FILE_BET = 'FILE_BET',
  GENERATED_BET = 'GENERATED_BET',
  REDUCED_BET = 'REDUCED_BET'
}

export type Coupon = {
  created: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modified: Maybe<Scalars['DateTime']['output']>;
};

export type CouponInput = {
  betMeta: InputMaybe<BetMetaInput>;
  contractType: ContractType;
  created: InputMaybe<Scalars['DateTime']['input']>;
  harryFlavor: InputMaybe<HarryFlavor>;
  harryMaxStake: InputMaybe<Scalars['Amount']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  modified: InputMaybe<Scalars['DateTime']['input']>;
  numberOfSystems: InputMaybe<Scalars['Int']['input']>;
  selections: HorseCouponSelectionsInput;
  stake: Scalars['Amount']['input'];
};

export interface CouponSelections {
  __typename?: 'CouponSelections';
  legs: Array<Leg>;
}

export type CreateApplicationInput = {
  greeting: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
};

export interface CreateApplicationResponse {
  __typename?: 'CreateApplicationResponse';
  status: CreateApplicationStatus;
}

export enum CreateApplicationStatus {
  ALREADY_MEMBER = 'ALREADY_MEMBER',
  APPLICATION_EXISTS = 'APPLICATION_EXISTS',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  JOINED = 'JOINED',
  QUEUED = 'QUEUED',
  TO_MANY_TEAMS = 'TO_MANY_TEAMS'
}

export type CreateParticipationInput = {
  gameId: Scalars['ID']['input'];
  numberOfShares: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type CreateRoundInput = {
  description: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['ID']['input'];
  gameInitiatorNumberOfBoughtShares: InputMaybe<Scalars['Int']['input']>;
  sales: SalesInput;
  teamId: Scalars['ID']['input'];
};

export type CreateSportCouponInput = {
  couponId: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['ID']['input'];
  payload: Scalars['JSON']['input'];
  teamId: Scalars['ID']['input'];
};

export type CreateTeamInput = {
  membershipPolicy: MembershipPolicy;
  teamName: Scalars['String']['input'];
};

export type CustomRotatingGameInitiatorsInput = {
  gameType: GameType;
  selectedMemberIds: Array<Scalars['Int']['input']>;
  teamId: Scalars['ID']['input'];
};

export type EmailMessageInput = {
  memberIds: Array<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  teamId: Scalars['ID']['input'];
};

export interface Game {
  __typename?: 'Game';
  endTime: Maybe<Scalars['DateTime']['output']>;
  estimatedJackpot: Maybe<Scalars['Int']['output']>;
  gameId: Maybe<GameId>;
  gameStatus: Maybe<GameStatus>;
  id: Scalars['ID']['output'];
  jackpotAmount: Maybe<Scalars['Float']['output']>;
  scheduledStartTime: Maybe<Scalars['DateTime']['output']>;
  tracks: Maybe<Array<Track>>;
}

export interface GameId {
  __typename?: 'GameId';
  gameAreaCode: Maybe<Scalars['Int']['output']>;
  gameDate: Maybe<Scalars['Date']['output']>;
  gameRaceNumber: Maybe<Scalars['Int']['output']>;
  gameType: Maybe<GameType>;
}

export type GameIdGameInitiator = {
  gameId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
};

export type GameInitiatorInput = {
  gameInitiator: GameIdGameInitiator;
  teamId: Scalars['ID']['input'];
};

export interface GameInitiatorNotUpdated {
  __typename?: 'GameInitiatorNotUpdated';
  gameId: Maybe<Scalars['String']['output']>;
  reason: Maybe<GameInitiatorReason>;
}

export enum GameInitiatorReason {
  BET_PLACED = 'BET_PLACED',
  GAME_CLOSED = 'GAME_CLOSED'
}

export interface GameInitiatorSchedule {
  __typename?: 'GameInitiatorSchedule';
  currentPosition: Maybe<Scalars['Int']['output']>;
  gameType: GameType;
  id: Scalars['ID']['output'];
  scheduleType: GameInitiatorScheduleType;
  selectedMembers: Maybe<Array<Member>>;
  teamId: Scalars['ID']['output'];
}

export enum GameInitiatorScheduleType {
  MANUAL = 'MANUAL',
  ROTATING_CUSTOM = 'ROTATING_CUSTOM',
  ROTATING_TEAM = 'ROTATING_TEAM'
}

export type GameInitiatorsInput = {
  gameInitiators: Array<InputMaybe<GameIdGameInitiator>>;
  teamId: Scalars['ID']['input'];
};

export { GameStatus };

export { GameType };

export type GameTypeInput = {
  gameTypes: Array<InputMaybe<GameType>>;
  teamId: Scalars['ID']['input'];
};

export type GenerateNewGameInitiatorCandidateLinkInput = {
  teamId: Scalars['ID']['input'];
};

export interface Grading {
  __typename?: 'Grading';
  coupons: Maybe<Array<Maybe<GradingCoupon>>>;
  dividend: Maybe<Scalars['JSON']['output']>;
  qualifyingUnits: Maybe<Scalars['JSON']['output']>;
}

export interface GradingCoupon {
  __typename?: 'GradingCoupon';
  dividend: Maybe<Scalars['JSON']['output']>;
  effectiveMarks: Maybe<Scalars['JSON']['output']>;
  id: Maybe<Scalars['String']['output']>;
  qualifyingCombinations: Maybe<Scalars['JSON']['output']>;
  qualifyingUnits: Maybe<Scalars['JSON']['output']>;
}

export enum HarryFlavor {
  CHANCE = 'CHANCE',
  FAVORITE = 'FAVORITE',
  ORIGINAL = 'ORIGINAL'
}

export interface HorseCoupon extends Coupon {
  __typename?: 'HorseCoupon';
  betMeta: Maybe<BetMeta>;
  contractType: ContractType;
  created: Maybe<Scalars['DateTime']['output']>;
  harryFlavor: Maybe<HarryFlavor>;
  harryMaxStake: Maybe<Scalars['Amount']['output']>;
  id: Scalars['ID']['output'];
  modified: Maybe<Scalars['DateTime']['output']>;
  numberOfSystems: Maybe<Scalars['Int']['output']>;
  selections: CouponSelections;
  /** Horse specific fields */
  stake: Scalars['Amount']['output'];
}

export type HorseCouponSelectionsInput = {
  legs: Array<LegInput>;
};

export enum InvitationSetting {
  ALL = 'ALL',
  TEAM_CAPTAIN = 'TEAM_CAPTAIN'
}

export type InvitationSettingsInput = {
  invitationSetting: InvitationSetting;
  teamId: Scalars['ID']['input'];
};

export type LeaveTeamInput = {
  teamId: Scalars['ID']['input'];
};

export interface Leg {
  __typename?: 'Leg';
  harryOpen: Maybe<Scalars['Boolean']['output']>;
  raceId: Scalars['String']['output'];
  value: Marks;
}

export type LegInput = {
  harryOpen: InputMaybe<Scalars['Boolean']['input']>;
  raceId: Scalars['String']['input'];
  value: MarksInput;
};

export interface Location {
  __typename?: 'Location';
  postalCode: Maybe<Scalars['String']['output']>;
  postalTown: Maybe<Scalars['String']['output']>;
}

export interface Marks {
  __typename?: 'Marks';
  marks: Array<Scalars['Int']['output']>;
  reserves: Maybe<Array<Scalars['Int']['output']>>;
}

export type MarksInput = {
  marks: Array<Scalars['Int']['input']>;
  reserves: InputMaybe<Array<Scalars['Int']['input']>>;
};

export interface Member {
  __typename?: 'Member';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  memberId: Scalars['Int']['output'];
  online?: Maybe<Scalars['Boolean']['output']>;
}

export type MemberInput = {
  memberId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export interface MemberTeam {
  __typename?: 'MemberTeam';
  activatedRoundsAmount: Maybe<Scalars['Int']['output']>;
  captain: Scalars['Int']['output'];
  gameTypes: Array<GameType>;
  /** Team */
  id: Scalars['ID']['output'];
  isTeamCaptain: Scalars['Boolean']['output'];
  memberAmount: Maybe<Scalars['Int']['output']>;
  /** MemberTeam */
  memberSince: Scalars['Date']['output'];
  membershipPolicy: MembershipPolicy;
  name: Scalars['String']['output'];
  /** Social Events */
  pendingRequests: Maybe<Scalars['Int']['output']>;
  ranking: Scalars['Int']['output'];
  subscriptions: Array<GameType>;
  teamImageUrl: Maybe<Scalars['String']['output']>;
  type: TeamType;
  unreadComments: Maybe<Scalars['Int']['output']>;
}

export enum MembershipPolicy {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PRIVATE = 'PRIVATE'
}

export interface Mutation {
  __typename?: 'Mutation';
  /**
   * Error codes:
   * GAME_INITIATOR_KEY_IS_NOT_VALID
   */
  addGameInitiatorCandidate: Array<Member>;
  batchBetUpload: BatchBetUploadData;
  createApplication: CreateApplicationResponse;
  /**
   * Buy share
   * --------------------------------------
   * RGS_SELF_EXCLUSION,
   * RGS_COVID_SELF_EXCLUSION,
   * RGS_TEMPORARY_SELF_EXCLUSION,
   * RGS_NAR_EXCLUSION,
   * RGS_NAR_UNDETERMINED,
   * RGS_DEPOSIT_LIMIT_NOT_SET,
   * RGS_HIGH_DEPOSIT_LIMIT,
   * RGS_NETLOSS_LIMIT_EXCEEDED,
   * RGS_NETLOSS_SERVER_ERROR,
   * RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED,
   * RGS_CASINO_LOSS_LIMIT_NOT_SET,
   * RGS_CASINO_HIGH_LOSS_LIMIT,
   * RGS_CASINO_LOSS_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET,
   * RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET,
   * RGS_CALL_FAILED,
   * PURCHASE_CLOSED
   * NOT_MEMBER_OF_TEAM
   * ALREADY_PURCHASED
   * TOTAL_MAX_SOLD_SHARES_EXCEEDED
   * MAX_NUMBER_SHARES_PER_MEMBER_EXCEEDED
   * BET_ALREADY_PLACED
   * INSUFFICIENT_FUNDS
   * GENERAL_ERROR
   * NOT_FOUND
   * ACCESS_DENIED
   * INPUT_CONSTRAINT_VIOLATION
   */
  createParticipation: Participant;
  /**
   * Create Round
   * --------------------------------------
   * RGS_SELF_EXCLUSION,
   * RGS_COVID_SELF_EXCLUSION,
   * RGS_TEMPORARY_SELF_EXCLUSION,
   * RGS_NAR_EXCLUSION,
   * RGS_NAR_UNDETERMINED,
   * RGS_DEPOSIT_LIMIT_NOT_SET,
   * RGS_HIGH_DEPOSIT_LIMIT,
   * RGS_NETLOSS_LIMIT_EXCEEDED,
   * RGS_NETLOSS_SERVER_ERROR,
   * RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED,
   * RGS_CASINO_LOSS_LIMIT_NOT_SET,
   * RGS_CASINO_HIGH_LOSS_LIMIT,
   * RGS_CASINO_LOSS_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET,
   * RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET,
   * RGS_CALL_FAILED,
   * ALREADY_ACTIVATED
   * INCORRECT_AMOUNT_OF_SHARES
   * WRONG_GAME_TYPE
   * INSUFFICIENT_FUNDS
   * GENERAL_ERROR
   * NOT_FOUND
   * LACKS_ROLE
   * ACCESS_DENIED
   * INPUT_CONSTRAINT_VIOLATION
   */
  createRound: Round;
  createSportCoupon: Coupon;
  /**
   * Error codes:
   *
   * TEAM_ALREADY_EXISTS,
   * MAX_NR_OF_TEAMS,
   * UPDATE_TEAM_DB_ERROR
   */
  createTeam: Team;
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   */
  generateNewGameInitiatorCandidateLink: Scalars['String']['output'];
  getMailNotificationSettings: Array<Maybe<NotificationSetting>>;
  /**
   * Error codes:
   *
   * IS_CAPTAIN,
   * IS_GAME_INITIATOR,
   * IS_PARTICIPATING,
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   */
  leaveTeam: Scalars['ID']['output'];
  optOutMail: Scalars['Boolean']['output'];
  /**
   * Before bets is split into transactions
   * --------------------------------------
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   * ROUND_NOT_ACTIVATED
   * MAX_NR_OF_BETS (cannot place more than 4 bets)
   * MUST_PARTICIPATE
   * INSUFFICIENT_FUNDS
   * GENERAL_ERROR
   *
   * On single transactions
   * ----------------------
   * INVALID_COUPON_STAKE (higher than 99999 kr)
   * INVALID_HARRY_MAX_STAKE (50 <= x <= 10000)
   * COUPON_ALREADY_PLACED
   * DOWNSTREAM_TIMEOUT (might have been placed, do not know)
   * GENERAL_ERROR
   *
   * From old betting system
   * V75_CLOSED,
   * V86_CLOSED,
   * OBSOLETE_MARKS,
   * INVALID_MARKS,
   * TOO_HIGH_AMOUNT,
   * TOO_LOW_AMOUNT,
   * BETTING_CLOSED,
   * AMOUNT_EXCEEDS_LIMIT,
   * BETTING_SYSTEM_CLOSED
   * SYSTEM_LIMIT_EXCEEDED
   *
   * From new bettingsystem
   * SELL_NOT_OPEN (The offering with id 'the-offering-id' is not open for new bets.)
   * INSUFFICIENT_FUNDS
   * BET_ALREADY_PLACED
   * FAILED_TO_GENERATE_BET(Generation failed, conditions too strict.)
   * CONDITIONS_TOO_STRICT(Generation failed, conditions too strict.)
   * STAKE_TOO_LOW (General error for stake is too low. Defined by Harry Rules.)
   * STAKE_TOO_HIGH (General error for stake is too high. Defined by Harry Rules.)
   */
  placeHorseBet: Scalars['Boolean']['output'];
  placeSportBet: Scalars['Boolean']['output'];
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   *
   * NOT_FOUND,
   * ROUND_NOT_ACTIVATED,
   * MUST_PARTICIPATE,
   * MAX_NR_OF_BATCH_BETS
   *
   * INVALID_NR_OF_SYSTEMS,
   * GAME_DATE_DO_NOT_MATCH,
   * NR_OF_RACES_DO_NOT_MATCH,
   * INSUFFICIENT_FUNDS,
   * INVALID_NR_OF_MARKS,
   * INVALID_TOTAL_COST,
   * INVALID_SYSTEM_LIMIT,
   * INVALID_SYSTEM_LIMIT_CUSTOM,
   * BATCH_BETS_EXISTS_IN_QUEUE
   *
   * Will not happen if BatchBetUploadData has been verified,
   * but in theory if placed directly:
   * --------------------------------
   * EMPTY_OR_NULL,
   * XML_VALIDATION_ERROR,
   * INVALID_NR_OF_SYSTEMS,
   * INVALID_GAME_TYPE_IN_COUPON,
   * EMPTY_COUPON_LIST,
   * COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE,
   * GAME_TYPE_AND_DATE_DO_NOT_EXIST
   * GENERAL_ERROR,
   */
  playBatchBetV2: Scalars['Boolean']['output'];
  /**
   * Error codes:
   * TEAM_IS_FULL,
   * NOT_FOUND,
   * TOO_MANY_TEAMS,
   * APPLICATION_NOT_IN_TEAM,
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   */
  processApplication: Scalars['ID']['output'];
  /**
   * Error codes:
   * APPLICATION_NOT_OWNED_BY_MEMBER
   */
  removeApplication: Scalars['ID']['output'];
  removeCoupon: Scalars['ID']['output'];
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   */
  removeGameInitiatorCandidate: Array<Member>;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM, (calling user)
   * LACKS_ROLE,
   * IS_CAPTAIN, (can't remove captain)
   * MEMBER_NOT_FOUND, (user to remove),
   * IS_PARTICIPATING
   */
  removeMember: Maybe<Scalars['ID']['output']>;
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * SUBSCRIPTION_NOT_FOUND
   * GENERAL_ERROR
   */
  removeSubscription: Scalars['ID']['output'];
  /**
   * Error codes:
   *
   * ACTIVE_ROUND_EXISTS,
   * MEMBERS_EXISTS,
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  removeTeam: Maybe<Scalars['ID']['output']>;
  /** id can be either couponId or tsn */
  resetImageAndDescription: BetMeta;
  /** id can be either couponId or tsn */
  saveBetDescription: BetMeta;
  saveCoupon: Coupon;
  saveSportCoupon: Coupon;
  sendEmail: Scalars['Boolean']['output'];
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * CUSTOM_GI_INVALID_NR_OF_MEMBERS
   */
  setCustomRotatingGameInitiators: GameInitiatorSchedule;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * NOT_FOUND,
   * GI_BET_IS_PLACED,
   * SELL_NOT_OPEN
   */
  setGameInitiator: Maybe<Round>;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  setGameInitiators: Maybe<SetGameInitiatorsResponse>;
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE
   */
  setRotatingGameInitiators: GameInitiatorSchedule;
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * SUBSCRIPTION_NOT_FOUND
   * RGS_SELF_EXCLUSION,
   * RGS_COVID_SELF_EXCLUSION,
   * RGS_TEMPORARY_SELF_EXCLUSION,
   * RGS_NAR_EXCLUSION,
   * RGS_NAR_UNDETERMINED,
   * RGS_DEPOSIT_LIMIT_NOT_SET,
   * RGS_HIGH_DEPOSIT_LIMIT,
   * RGS_NETLOSS_LIMIT_EXCEEDED,
   * RGS_NETLOSS_SERVER_ERROR,
   * RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED,
   * RGS_CASINO_LOSS_LIMIT_NOT_SET,
   * RGS_CASINO_HIGH_LOSS_LIMIT,
   * RGS_CASINO_LOSS_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET,
   * RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET,
   * RGS_CALL_FAILED,
   * GENERAL_ERROR
   */
  setSubscriptionAllRounds: Maybe<BetSubscription>;
  /**
   * Error codes:
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * SUBSCRIPTION_NOT_FOUND
   * RGS_SELF_EXCLUSION,
   * RGS_COVID_SELF_EXCLUSION,
   * RGS_TEMPORARY_SELF_EXCLUSION,
   * RGS_NAR_EXCLUSION,
   * RGS_NAR_UNDETERMINED,
   * RGS_DEPOSIT_LIMIT_NOT_SET,
   * RGS_HIGH_DEPOSIT_LIMIT,
   * RGS_NETLOSS_LIMIT_EXCEEDED,
   * RGS_NETLOSS_SERVER_ERROR,
   * RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED,
   * RGS_CASINO_LOSS_LIMIT_NOT_SET,
   * RGS_CASINO_HIGH_LOSS_LIMIT,
   * RGS_CASINO_LOSS_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED,
   * RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET,
   * RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET,
   * RGS_CALL_FAILED,
   * GENERAL_ERROR
   */
  setSubscriptionNumberOfRounds: Maybe<BetSubscription>;
  /** Not yet implemented, will come in part two */
  setSubscriptionSelectedRounds: Maybe<BetSubscription>;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateCaptain: Maybe<Team>;
  /**
   * Error codes:
   *
   * GAME_TYPE_LIST_IS_EMPTY,
   * ACTIVE_ROUND_OF_GAME_TYPE_EXISTS,
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateGameTypes: Maybe<Team>;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateInvitationSettings: Maybe<Team>;
  /**
   * Update Round
   * --------------------------------------
   * MAX_SHARES_PER_PERSON_TOO_LOW
   * BET_ALREADY_PLACED
   * SHARED_BET_IS_INACTIVE
   * GENERAL_ERROR
   * NOT_FOUND
   * LACKS_ROLE
   * ACCESS_DENIED
   * INPUT_CONSTRAINT_VIOLATION
   */
  updateRound: Round;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateSearchSettings: Maybe<Team>;
  /**
   * Error codes:
   *
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateSystemsLimit: Maybe<Team>;
  /**
   * Error codes:
   *
   * TEAM_NAME_ALREADY_EXISTS
   * ACCESS_DENIED,
   * NOT_MEMBER_OF_TEAM,
   * LACKS_ROLE,
   * UPDATE_TEAM_DB_ERROR
   */
  updateTeamInfo: Maybe<Team>;
  /** id can be either couponId or tsn */
  uploadBetImage: BetMeta;
}


export type MutationAddGameInitiatorCandidateArgs = {
  input: AddGameInitiatorCandidateInput;
};


export type MutationBatchBetUploadArgs = {
  couponId: InputMaybe<Scalars['String']['input']>;
  file: InputMaybe<Scalars['Upload']['input']>;
};


export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};


export type MutationCreateParticipationArgs = {
  input: CreateParticipationInput;
};


export type MutationCreateRoundArgs = {
  input: CreateRoundInput;
};


export type MutationCreateSportCouponArgs = {
  input: CreateSportCouponInput;
};


export type MutationCreateTeamArgs = {
  image: InputMaybe<Scalars['Upload']['input']>;
  input: CreateTeamInput;
};


export type MutationGenerateNewGameInitiatorCandidateLinkArgs = {
  input: GenerateNewGameInitiatorCandidateLinkInput;
};


export type MutationLeaveTeamArgs = {
  input: LeaveTeamInput;
};


export type MutationOptOutMailArgs = {
  input: Scalars['String']['input'];
};


export type MutationPlaceHorseBetArgs = {
  input: PlaceHorseBetInput;
};


export type MutationPlaceSportBetArgs = {
  input: PlaceSportBetInput;
};


export type MutationPlayBatchBetV2Args = {
  input: PlayBatchBetInput;
};


export type MutationProcessApplicationArgs = {
  input: ProcessApplicationInput;
};


export type MutationRemoveApplicationArgs = {
  input: RemoveApplicationInput;
};


export type MutationRemoveCouponArgs = {
  input: RemoveCouponInput;
};


export type MutationRemoveGameInitiatorCandidateArgs = {
  input: RemoveGameInitiatorCandidateInput;
};


export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};


export type MutationRemoveSubscriptionArgs = {
  input: RemoveSubscriptionInput;
};


export type MutationRemoveTeamArgs = {
  input: RemoveTeamInput;
};


export type MutationResetImageAndDescriptionArgs = {
  input: ResetImageAndDescriptionInput;
};


export type MutationSaveBetDescriptionArgs = {
  input: SaveBetDescriptionInput;
};


export type MutationSaveCouponArgs = {
  input: SaveCouponInput;
};


export type MutationSaveSportCouponArgs = {
  input: SaveSportCouponInput;
};


export type MutationSendEmailArgs = {
  input: EmailMessageInput;
};


export type MutationSetCustomRotatingGameInitiatorsArgs = {
  input: CustomRotatingGameInitiatorsInput;
};


export type MutationSetGameInitiatorArgs = {
  input: GameInitiatorInput;
};


export type MutationSetGameInitiatorsArgs = {
  input: GameInitiatorsInput;
};


export type MutationSetRotatingGameInitiatorsArgs = {
  input: RotatingGameInitiatorsInput;
};


export type MutationSetSubscriptionAllRoundsArgs = {
  input: SubscriptionAllRoundsInput;
};


export type MutationSetSubscriptionNumberOfRoundsArgs = {
  input: SubscriptionNumberOfRoundsInput;
};


export type MutationSetSubscriptionSelectedRoundsArgs = {
  input: SubscriptionSelectedRoundsInput;
};


export type MutationUpdateCaptainArgs = {
  input: MemberInput;
};


export type MutationUpdateGameTypesArgs = {
  input: GameTypeInput;
};


export type MutationUpdateInvitationSettingsArgs = {
  input: InvitationSettingsInput;
};


export type MutationUpdateRoundArgs = {
  input: UpdateRoundInput;
};


export type MutationUpdateSearchSettingsArgs = {
  input: SearchSettingInput;
};


export type MutationUpdateSystemsLimitArgs = {
  input: SystemsLimitInput;
};


export type MutationUpdateTeamInfoArgs = {
  input: TeamInfoInput;
};


export type MutationUploadBetImageArgs = {
  image: InputMaybe<Scalars['Upload']['input']>;
  input: UploadBetImageInput;
};

export interface NotificationSetting {
  __typename?: 'NotificationSetting';
  activated: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  label: Maybe<Scalars['String']['output']>;
}

export enum OfferingStatus {
  CANCELED = 'CANCELED',
  DECIDED = 'DECIDED',
  FINALIZED = 'FINALIZED',
  GRADED = 'GRADED',
  GRADING = 'GRADING',
  PAYOUT_HALTED = 'PAYOUT_HALTED',
  PAYOUT_OPEN = 'PAYOUT_OPEN',
  PUBLISHING = 'PUBLISHING',
  REVOKING_GRADING = 'REVOKING_GRADING',
  SELL_CLOSED = 'SELL_CLOSED',
  SELL_OPEN = 'SELL_OPEN',
  UNDER_REVIEW = 'UNDER_REVIEW',
  UNPUBLISHED = 'UNPUBLISHED',
  UPCOMING = 'UPCOMING'
}

export interface OfferingSummary {
  __typename?: 'OfferingSummary';
  legs: Scalars['JSON']['output'];
  pools: Scalars['JSON']['output'];
}

export interface Participant {
  __typename?: 'Participant';
  gameId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  member: Member;
  numberOfShares: Scalars['Int']['output'];
  teamId: Scalars['Int']['output'];
  totalCost: Scalars['Amount']['output'];
}

export type PlaceHorseBetInput = {
  coupons: Array<CouponInput>;
  gameId: Scalars['ID']['input'];
  smsReceipt: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type PlaceSportBetInput = {
  canceledLegs: Array<Scalars['String']['input']>;
  coupons: Array<SportCouponInput>;
  gameId: Scalars['ID']['input'];
  smsReceipt: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type PlayBatchBetInput = {
  batchBetUploadId: Scalars['ID']['input'];
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type ProcessApplicationInput = {
  approve: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export interface Query {
  __typename?: 'Query';
  activeGames: Array<Game>;
  applications: Array<Application>;
  batchBetTeams: Array<Maybe<BatchBetTeam>>;
  batchBetUploadData: BatchBetUploadData;
  currentGames: Maybe<Array<Maybe<Game>>>;
  futureGames: Maybe<Array<Game>>;
  futureRounds: Array<Round>;
  game: Game;
  gameInitiatorCandidateLink: Scalars['String']['output'];
  gameInitiatorCandidates: Array<Member>;
  gameInitiatorMemberships: Array<TeamMembership>;
  gameInitiatorSchedule: GameInitiatorSchedule;
  gameInitiatorSchedules: Array<GameInitiatorSchedule>;
  getCoupons: Array<Coupon>;
  getMyCoupons: Array<Coupon>;
  historicalGames: Array<Game>;
  memberParticipations: Array<Participant>;
  memberTeams: Array<MemberTeam>;
  myTeams: Maybe<Array<MemberTeam>>;
  participations: Maybe<Array<Participant>>;
  roundBalance: Maybe<Scalars['Amount']['output']>;
  roundParticipants: Maybe<Array<Participant>>;
  rounds: Maybe<Array<Round>>;
  sharedReceiptV2: Maybe<SharedReceiptV2>;
  subscription: Maybe<BetSubscription>;
  team: Maybe<Team>;
  teamApplications: Array<Application>;
  teamCount: Maybe<Scalars['Int']['output']>;
  teamMembers: Maybe<Array<Member>>;
  teamMemberships: Array<TeamMembership>;
  teamRound: Maybe<Round>;
  teamRounds: Array<Round>;
  teamSearch: TeamSearchResponse;
}


export type QueryBatchBetTeamsArgs = {
  gameId: Scalars['ID']['input'];
};


export type QueryBatchBetUploadDataArgs = {
  batchBetUploadId: Scalars['String']['input'];
  isReduced: Scalars['Boolean']['input'];
};


export type QueryCurrentGamesArgs = {
  numberOfGames: Scalars['Int']['input'];
};


export type QueryFutureRoundsArgs = {
  gameType: InputMaybe<GameType>;
  teamId: Scalars['ID']['input'];
};


export type QueryGameArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGameInitiatorCandidateLinkArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryGameInitiatorCandidatesArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryGameInitiatorMembershipsArgs = {
  history: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
};


export type QueryGameInitiatorScheduleArgs = {
  gameType: GameType;
  teamId: Scalars['ID']['input'];
};


export type QueryGameInitiatorSchedulesArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryGetCouponsArgs = {
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type QueryGetMyCouponsArgs = {
  gameId: Scalars['ID']['input'];
};


export type QueryMemberParticipationsArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryMyTeamsArgs = {
  refreshTeams: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryParticipationsArgs = {
  checkAllActiveGames: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRoundBalanceArgs = {
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type QueryRoundParticipantsArgs = {
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type QuerySharedReceiptV2Args = {
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  gameType: InputMaybe<GameType>;
  teamId: Scalars['ID']['input'];
};


export type QueryTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryTeamApplicationsArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryTeamMembersArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryTeamMembershipsArgs = {
  history: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
};


export type QueryTeamRoundArgs = {
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type QueryTeamRoundsArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryTeamSearchArgs = {
  input: TeamSearchFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
};

export type RemoveApplicationInput = {
  id: Scalars['ID']['input'];
};

export type RemoveCouponInput = {
  couponId: Scalars['ID']['input'];
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type RemoveGameInitiatorCandidateInput = {
  memberId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type RemoveMemberInput = {
  memberId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type RemoveSubscriptionInput = {
  gameType: GameType;
  id: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type RemoveTeamInput = {
  teamId: Scalars['ID']['input'];
};

export type ResetImageAndDescriptionInput = {
  betMetaIds: BetMetaIds;
  roundId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type RotatingGameInitiatorsInput = {
  gameType: GameType;
  teamId: Scalars['ID']['input'];
};

export interface Round {
  __typename?: 'Round';
  betMethod: Maybe<RoundBetMethod>;
  description: Maybe<Scalars['String']['output']>;
  game: Maybe<Game>;
  gameId: Scalars['ID']['output'];
  gameInitiator: Maybe<Member>;
  id: Scalars['ID']['output'];
  isGameInitiator: Scalars['Boolean']['output'];
  sales: Maybe<Sales>;
  salesDefaults: Maybe<SalesDefaults>;
  state: Maybe<RoundState>;
  teamId: Scalars['ID']['output'];
}

export { RoundBetMethod };

export { RoundState };

export interface Sales {
  __typename?: 'Sales';
  buyLimit: Maybe<Scalars['Int']['output']>;
  buyLimitPerPerson: Maybe<Scalars['Int']['output']>;
  deadline: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  price: Maybe<Scalars['Amount']['output']>;
  refundAmount: Maybe<Scalars['Amount']['output']>;
  scheduledReleases: Maybe<Array<ScheduledRelease>>;
  totalSalesAmount: Maybe<Scalars['Amount']['output']>;
}

export interface SalesDefaults {
  __typename?: 'SalesDefaults';
  buyLimitPerPerson: Scalars['Int']['output'];
  price: Scalars['Amount']['output'];
}

export type SalesInput = {
  buyLimit: InputMaybe<Scalars['Int']['input']>;
  buyLimitPerPerson: Scalars['Int']['input'];
  deadline: Scalars['DateTime']['input'];
  price: Scalars['Amount']['input'];
  scheduledReleases: InputMaybe<Array<ScheduledReleaseInput>>;
};

export type SaveBetDescriptionInput = {
  betMetaIds: BetMetaIds;
  description: Scalars['String']['input'];
  roundId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type SaveCouponInput = {
  coupon: CouponInput;
  gameId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type SaveSportCouponInput = {
  couponId: Scalars['String']['input'];
  gameId: Scalars['ID']['input'];
  modified: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
  teamId: Scalars['ID']['input'];
};

export interface ScheduledRelease {
  __typename?: 'ScheduledRelease';
  id: Scalars['ID']['output'];
  numberOfShares: Scalars['Int']['output'];
  time: Scalars['DateTime']['output'];
}

export type ScheduledReleaseInput = {
  id: Scalars['ID']['input'];
  numberOfShares: Scalars['Int']['input'];
  time: Scalars['DateTime']['input'];
};

export type SearchSettingInput = {
  locationVisible: Scalars['Boolean']['input'];
  membershipPolicy: MembershipPolicy;
  teamId: Scalars['ID']['input'];
};

export interface SetGameInitiatorsResponse {
  __typename?: 'SetGameInitiatorsResponse';
  rounds: Maybe<Array<Maybe<Round>>>;
  roundsNotUpdated: Maybe<Array<Maybe<GameInitiatorNotUpdated>>>;
}

export interface ShareInfoGrading {
  __typename?: 'ShareInfoGrading';
  dividend: Maybe<Scalars['JSON']['output']>;
  qualifyingUnits: Maybe<Scalars['JSON']['output']>;
}

export interface ShareInfoV2 {
  __typename?: 'ShareInfoV2';
  grading: Maybe<ShareInfoGrading>;
  winnings: Maybe<ShareInfoWinnings>;
}

export interface ShareInfoWinnings {
  __typename?: 'ShareInfoWinnings';
  my: Maybe<Scalars['Long']['output']>;
  perShare: Scalars['Long']['output'];
  total: Scalars['Long']['output'];
}

export interface SharedReceiptV2 {
  __typename?: 'SharedReceiptV2';
  bets: Array<BetWithDetails>;
  shareInfo: ShareInfoV2;
}

export interface SportCoupon extends Coupon {
  __typename?: 'SportCoupon';
  /** Sport specific fields */
  couponCreator: Maybe<Scalars['ID']['output']>;
  created: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modified: Maybe<Scalars['DateTime']['output']>;
  payload: Maybe<Scalars['JSON']['output']>;
}

export type SportCouponInput = {
  /** Sport specific fields */
  couponCreator: InputMaybe<Scalars['ID']['input']>;
  created: InputMaybe<Scalars['DateTime']['input']>;
  harryMaxStake: InputMaybe<Scalars['Amount']['input']>;
  id: Scalars['ID']['input'];
  modified: InputMaybe<Scalars['DateTime']['input']>;
  payload: Scalars['JSON']['input'];
  stake: InputMaybe<Scalars['Amount']['input']>;
};

export type SubscriptionAllRoundsInput = {
  gameType: GameType;
  maxAmount: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type SubscriptionNumberOfRoundsInput = {
  gameType: GameType;
  maxAmount: Scalars['Int']['input'];
  numberOfRounds: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type SubscriptionSelectedRoundsInput = {
  gameType: GameType;
  maxAmount: Scalars['Int']['input'];
  selectedRounds: Array<InputMaybe<Scalars['ID']['input']>>;
  teamId: Scalars['ID']['input'];
};

export enum SubscriptionType {
  ALL_ROUNDS = 'ALL_ROUNDS',
  NUMBER_OF_ROUNDS = 'NUMBER_OF_ROUNDS',
  SELECTED_ROUNDS = 'SELECTED_ROUNDS'
}

export type SystemsLimitInput = {
  systemsLimit: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['ID']['input'];
};

export interface Team {
  __typename?: 'Team';
  batchBetAllowed: Scalars['Boolean']['output'];
  captain: Member;
  createdDate: Scalars['Date']['output'];
  description: Maybe<Scalars['String']['output']>;
  gameTypes: Array<GameType>;
  /** Team basics */
  id: Scalars['ID']['output'];
  invitationSetting: InvitationSetting;
  isTeamCaptain: Scalars['Boolean']['output'];
  location: Maybe<Location>;
  locationVisible: Scalars['Boolean']['output'];
  /** Team settings */
  membershipPolicy: MembershipPolicy;
  multipleReleasesAllowed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Team info */
  ranking: Scalars['Int']['output'];
  systemsLimit: Maybe<Scalars['Int']['output']>;
  teamImageUrl: Maybe<Scalars['String']['output']>;
  type: TeamType;
  /** Field feed from custom resolver */
  vacantMemberships: Scalars['Int']['output'];
  /** Team restrictions */
  visible: Scalars['Boolean']['output'];
}

export type TeamInfoInput = {
  description: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  teamId: Scalars['ID']['input'];
};

export interface TeamMembership {
  __typename?: 'TeamMembership';
  id: Scalars['ID']['output'];
  member: Member;
  memberSince: Scalars['Date']['output'];
  participatingHistory: Maybe<Scalars['String']['output']>;
  roles: Array<TeamRole>;
  teamId: Scalars['ID']['output'];
}

export enum TeamRole {
  ACTIVE_BETTER_CANDIDATE = 'ACTIVE_BETTER_CANDIDATE',
  ADMINISTRATOR = 'ADMINISTRATOR',
  CAPTAIN = 'CAPTAIN',
  MEMBER = 'MEMBER'
}

export type TeamSearchFilter = {
  costMax: InputMaybe<Scalars['Int']['input']>;
  costMin: InputMaybe<Scalars['Int']['input']>;
  gameTypes: InputMaybe<Scalars['String']['input']>;
  hideFullTeams: InputMaybe<Scalars['Boolean']['input']>;
  hideTeamsWithoutImage: InputMaybe<Scalars['Boolean']['input']>;
  hideTeamsWithoutPostalTown: InputMaybe<Scalars['Boolean']['input']>;
  lat: InputMaybe<Scalars['Float']['input']>;
  lng: InputMaybe<Scalars['Float']['input']>;
  nbrMembersMax: InputMaybe<Scalars['Int']['input']>;
  nbrMembersMin: InputMaybe<Scalars['Int']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  shuffle: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  shuffleRecentActive?: InputMaybe<Scalars['Boolean']['input']>;
  shuffleSeed: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<TeamSearchSortOrder>;
  teamPolicy: InputMaybe<MembershipPolicy>;
  teamType: InputMaybe<TeamType>;
  text: InputMaybe<Scalars['String']['input']>;
};

export interface TeamSearchResponse {
  __typename?: 'TeamSearchResponse';
  nbrOfResults: Scalars['Int']['output'];
  results: Array<TeamSearchResult>;
}

export interface TeamSearchResult {
  __typename?: 'TeamSearchResult';
  captainName: Scalars['String']['output'];
  gameTypes: Array<GameType>;
  maxShareCost: Scalars['Int']['output'];
  memberLimit: Scalars['Int']['output'];
  membershipPolicy: MembershipPolicy;
  minShareCost: Scalars['Int']['output'];
  numberOfMembers: Scalars['Int']['output'];
  postalCode: Maybe<Scalars['Int']['output']>;
  postalTown: Maybe<Scalars['String']['output']>;
  ranking: Scalars['Int']['output'];
  sticky: Scalars['Boolean']['output'];
  teamDescription: Maybe<Scalars['String']['output']>;
  teamId: Scalars['ID']['output'];
  teamImageRef: Maybe<Scalars['String']['output']>;
  teamName: Scalars['String']['output'];
  teamType: TeamType;
  updatedDateTimestamp: Scalars['String']['output'];
}

export enum TeamSearchSortOrder {
  RECENT_ACTIVE = 'RECENT_ACTIVE',
  SHARE_COST = 'SHARE_COST',
  WINNINGS_TREND = 'WINNINGS_TREND'
}

export enum TeamType {
  BEGINNER = 'BEGINNER',
  CAMPAIGN = 'CAMPAIGN',
  STANDARD = 'STANDARD'
}

export interface Track {
  __typename?: 'Track';
  id: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
}

export type UpdateRoundInput = {
  description: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['ID']['input'];
  sales: SalesInput;
  teamId: Scalars['ID']['input'];
};

export type UploadBetImageInput = {
  betMetaIds: BetMetaIds;
  roundId: Scalars['ID']['input'];
  websocketIds: InputMaybe<WebsocketIdsInput>;
};

export type WebsocketIdsInput = {
  clientName: InputMaybe<Scalars['String']['input']>;
  route: InputMaybe<Scalars['String']['input']>;
};

export type AmountsKeySpecifier = ('netDividend' | 'payoutFee' | 'sellingFee' | 'sellingFeeRefund' | 'stakeRefund' | 'totalCost' | 'totalPayout' | 'totalRefund' | AmountsKeySpecifier)[];
export type AmountsFieldPolicy = {
	netDividend?: FieldPolicy<any> | FieldReadFunction<any>,
	payoutFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFeeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	stakeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPayout?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRefund?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ApplicationKeySpecifier = ('createdDate' | 'id' | 'member' | 'team' | 'text' | ApplicationKeySpecifier)[];
export type ApplicationFieldPolicy = {
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetSummaryKeySpecifier = ('numberOfCombinations' | 'numberOfCoupons' | 'numberOfSystems' | 'totalCost' | BatchBetSummaryKeySpecifier)[];
export type BatchBetSummaryFieldPolicy = {
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetTeamKeySpecifier = ('name' | 'teamId' | BatchBetTeamKeySpecifier)[];
export type BatchBetTeamFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetUploadDataKeySpecifier = ('batchBetUploadId' | 'games' | 'message' | 'status' | 'summary' | BatchBetUploadDataKeySpecifier)[];
export type BatchBetUploadDataFieldPolicy = {
	batchBetUploadId?: FieldPolicy<any> | FieldReadFunction<any>,
	games?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetMetaKeySpecifier = ('couponId' | 'description' | 'id' | 'imageRef' | BetMetaKeySpecifier)[];
export type BetMetaFieldPolicy = {
	couponId?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageRef?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetSubscriptionKeySpecifier = ('id' | 'maxAmount' | 'numberOfRounds' | 'numberOfRoundsLeft' | 'selectedRounds' | 'type' | BetSubscriptionKeySpecifier)[];
export type BetSubscriptionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	maxAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRoundsLeft?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetWithDetailsKeySpecifier = ('amounts' | 'bet' | 'betMeta' | 'betType' | 'contract' | 'id' | 'offering' | BetWithDetailsKeySpecifier)[];
export type BetWithDetailsFieldPolicy = {
	amounts?: FieldPolicy<any> | FieldReadFunction<any>,
	bet?: FieldPolicy<any> | FieldReadFunction<any>,
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	betType?: FieldPolicy<any> | FieldReadFunction<any>,
	contract?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	offering?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsBetKeySpecifier = ('coupons' | 'currency' | 'grading' | 'numberOfSystems' | 'offeringId' | 'placedAt' | 'stake' | 'status' | 'tsn' | 'type' | 'vertical' | CbsBetKeySpecifier)[];
export type CbsBetFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	placedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tsn?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vertical?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsCouponKeySpecifier = ('id' | 'numberOfCombinations' | 'numberOfSystems' | 'selections' | 'stake' | 'stakePerCombination' | 'unitsPerCombination' | CbsCouponKeySpecifier)[];
export type CbsCouponFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	stakePerCombination?: FieldPolicy<any> | FieldReadFunction<any>,
	unitsPerCombination?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsOfferingKeySpecifier = ('status' | 'summary' | 'unitStake' | CbsOfferingKeySpecifier)[];
export type CbsOfferingFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>,
	unitStake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContractKeySpecifier = ('maxCost' | 'offeringId' | 'options' | 'owner' | 'stake' | 'type' | ContractKeySpecifier)[];
export type ContractFieldPolicy = {
	maxCost?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponKeySpecifier = ('created' | 'id' | 'modified' | CouponKeySpecifier)[];
export type CouponFieldPolicy = {
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponSelectionsKeySpecifier = ('legs' | CouponSelectionsKeySpecifier)[];
export type CouponSelectionsFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateApplicationResponseKeySpecifier = ('status' | CreateApplicationResponseKeySpecifier)[];
export type CreateApplicationResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameKeySpecifier = ('endTime' | 'estimatedJackpot' | 'gameId' | 'gameStatus' | 'id' | 'jackpotAmount' | 'scheduledStartTime' | 'tracks' | GameKeySpecifier)[];
export type GameFieldPolicy = {
	endTime?: FieldPolicy<any> | FieldReadFunction<any>,
	estimatedJackpot?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	jackpotAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledStartTime?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameIdKeySpecifier = ('gameAreaCode' | 'gameDate' | 'gameRaceNumber' | 'gameType' | GameIdKeySpecifier)[];
export type GameIdFieldPolicy = {
	gameAreaCode?: FieldPolicy<any> | FieldReadFunction<any>,
	gameDate?: FieldPolicy<any> | FieldReadFunction<any>,
	gameRaceNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorNotUpdatedKeySpecifier = ('gameId' | 'reason' | GameInitiatorNotUpdatedKeySpecifier)[];
export type GameInitiatorNotUpdatedFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	reason?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorScheduleKeySpecifier = ('currentPosition' | 'gameType' | 'id' | 'scheduleType' | 'selectedMembers' | 'teamId' | GameInitiatorScheduleKeySpecifier)[];
export type GameInitiatorScheduleFieldPolicy = {
	currentPosition?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleType?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingKeySpecifier = ('coupons' | 'dividend' | 'qualifyingUnits' | GradingKeySpecifier)[];
export type GradingFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingCouponKeySpecifier = ('dividend' | 'effectiveMarks' | 'id' | 'qualifyingCombinations' | 'qualifyingUnits' | GradingCouponKeySpecifier)[];
export type GradingCouponFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	effectiveMarks?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HorseCouponKeySpecifier = ('betMeta' | 'contractType' | 'created' | 'harryFlavor' | 'harryMaxStake' | 'id' | 'modified' | 'numberOfSystems' | 'selections' | 'stake' | HorseCouponKeySpecifier)[];
export type HorseCouponFieldPolicy = {
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	contractType?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	harryFlavor?: FieldPolicy<any> | FieldReadFunction<any>,
	harryMaxStake?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LegKeySpecifier = ('harryOpen' | 'raceId' | 'value' | LegKeySpecifier)[];
export type LegFieldPolicy = {
	harryOpen?: FieldPolicy<any> | FieldReadFunction<any>,
	raceId?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LocationKeySpecifier = ('postalCode' | 'postalTown' | LocationKeySpecifier)[];
export type LocationFieldPolicy = {
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MarksKeySpecifier = ('marks' | 'reserves' | MarksKeySpecifier)[];
export type MarksFieldPolicy = {
	marks?: FieldPolicy<any> | FieldReadFunction<any>,
	reserves?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberKeySpecifier = ('firstName' | 'id' | 'imageUrl' | 'lastName' | 'memberId' | 'online' | MemberKeySpecifier)[];
export type MemberFieldPolicy = {
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	memberId?: FieldPolicy<any> | FieldReadFunction<any>,
	online?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberTeamKeySpecifier = ('activatedRoundsAmount' | 'captain' | 'gameTypes' | 'id' | 'isTeamCaptain' | 'memberAmount' | 'memberSince' | 'membershipPolicy' | 'name' | 'pendingRequests' | 'ranking' | 'subscriptions' | 'teamImageUrl' | 'type' | 'unreadComments' | MemberTeamKeySpecifier)[];
export type MemberTeamFieldPolicy = {
	activatedRoundsAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	memberAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	pendingRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	subscriptions?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	unreadComments?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addGameInitiatorCandidate' | 'batchBetUpload' | 'createApplication' | 'createParticipation' | 'createRound' | 'createSportCoupon' | 'createTeam' | 'generateNewGameInitiatorCandidateLink' | 'getMailNotificationSettings' | 'leaveTeam' | 'optOutMail' | 'placeHorseBet' | 'placeSportBet' | 'playBatchBetV2' | 'processApplication' | 'removeApplication' | 'removeCoupon' | 'removeGameInitiatorCandidate' | 'removeMember' | 'removeSubscription' | 'removeTeam' | 'resetImageAndDescription' | 'saveBetDescription' | 'saveCoupon' | 'saveSportCoupon' | 'sendEmail' | 'setCustomRotatingGameInitiators' | 'setGameInitiator' | 'setGameInitiators' | 'setRotatingGameInitiators' | 'setSubscriptionAllRounds' | 'setSubscriptionNumberOfRounds' | 'setSubscriptionSelectedRounds' | 'updateCaptain' | 'updateGameTypes' | 'updateInvitationSettings' | 'updateRound' | 'updateSearchSettings' | 'updateSystemsLimit' | 'updateTeamInfo' | 'uploadBetImage' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUpload?: FieldPolicy<any> | FieldReadFunction<any>,
	createApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	createParticipation?: FieldPolicy<any> | FieldReadFunction<any>,
	createRound?: FieldPolicy<any> | FieldReadFunction<any>,
	createSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	createTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	generateNewGameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	getMailNotificationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	leaveTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	optOutMail?: FieldPolicy<any> | FieldReadFunction<any>,
	placeHorseBet?: FieldPolicy<any> | FieldReadFunction<any>,
	placeSportBet?: FieldPolicy<any> | FieldReadFunction<any>,
	playBatchBetV2?: FieldPolicy<any> | FieldReadFunction<any>,
	processApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	removeGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	removeMember?: FieldPolicy<any> | FieldReadFunction<any>,
	removeSubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	removeTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	resetImageAndDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveBetDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	saveSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	sendEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	setCustomRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionAllRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionNumberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionSelectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	updateCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	updateGameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	updateInvitationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateRound?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSearchSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSystemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTeamInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadBetImage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationSettingKeySpecifier = ('activated' | 'id' | 'label' | NotificationSettingKeySpecifier)[];
export type NotificationSettingFieldPolicy = {
	activated?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OfferingSummaryKeySpecifier = ('legs' | 'pools' | OfferingSummaryKeySpecifier)[];
export type OfferingSummaryFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>,
	pools?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ParticipantKeySpecifier = ('gameId' | 'id' | 'member' | 'numberOfShares' | 'teamId' | 'totalCost' | ParticipantKeySpecifier)[];
export type ParticipantFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('activeGames' | 'applications' | 'batchBetTeams' | 'batchBetUploadData' | 'currentGames' | 'futureGames' | 'futureRounds' | 'game' | 'gameInitiatorCandidateLink' | 'gameInitiatorCandidates' | 'gameInitiatorMemberships' | 'gameInitiatorSchedule' | 'gameInitiatorSchedules' | 'getCoupons' | 'getMyCoupons' | 'historicalGames' | 'memberParticipations' | 'memberTeams' | 'myTeams' | 'participations' | 'roundBalance' | 'roundParticipants' | 'rounds' | 'sharedReceiptV2' | 'subscription' | 'team' | 'teamApplications' | 'teamCount' | 'teamMembers' | 'teamMemberships' | 'teamRound' | 'teamRounds' | 'teamSearch' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	activeGames?: FieldPolicy<any> | FieldReadFunction<any>,
	applications?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUploadData?: FieldPolicy<any> | FieldReadFunction<any>,
	currentGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidates?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedule?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedules?: FieldPolicy<any> | FieldReadFunction<any>,
	getCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	historicalGames?: FieldPolicy<any> | FieldReadFunction<any>,
	memberParticipations?: FieldPolicy<any> | FieldReadFunction<any>,
	memberTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	myTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	participations?: FieldPolicy<any> | FieldReadFunction<any>,
	roundBalance?: FieldPolicy<any> | FieldReadFunction<any>,
	roundParticipants?: FieldPolicy<any> | FieldReadFunction<any>,
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	sharedReceiptV2?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	teamApplications?: FieldPolicy<any> | FieldReadFunction<any>,
	teamCount?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRound?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	teamSearch?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoundKeySpecifier = ('betMethod' | 'description' | 'game' | 'gameId' | 'gameInitiator' | 'id' | 'isGameInitiator' | 'sales' | 'salesDefaults' | 'state' | 'teamId' | RoundKeySpecifier)[];
export type RoundFieldPolicy = {
	betMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	sales?: FieldPolicy<any> | FieldReadFunction<any>,
	salesDefaults?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesKeySpecifier = ('buyLimit' | 'buyLimitPerPerson' | 'deadline' | 'id' | 'price' | 'refundAmount' | 'scheduledReleases' | 'totalSalesAmount' | SalesKeySpecifier)[];
export type SalesFieldPolicy = {
	buyLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	deadline?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>,
	refundAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledReleases?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSalesAmount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesDefaultsKeySpecifier = ('buyLimitPerPerson' | 'price' | SalesDefaultsKeySpecifier)[];
export type SalesDefaultsFieldPolicy = {
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledReleaseKeySpecifier = ('id' | 'numberOfShares' | 'time' | ScheduledReleaseKeySpecifier)[];
export type ScheduledReleaseFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	time?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetGameInitiatorsResponseKeySpecifier = ('rounds' | 'roundsNotUpdated' | SetGameInitiatorsResponseKeySpecifier)[];
export type SetGameInitiatorsResponseFieldPolicy = {
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	roundsNotUpdated?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoGradingKeySpecifier = ('dividend' | 'qualifyingUnits' | ShareInfoGradingKeySpecifier)[];
export type ShareInfoGradingFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoV2KeySpecifier = ('grading' | 'winnings' | ShareInfoV2KeySpecifier)[];
export type ShareInfoV2FieldPolicy = {
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	winnings?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoWinningsKeySpecifier = ('my' | 'perShare' | 'total' | ShareInfoWinningsKeySpecifier)[];
export type ShareInfoWinningsFieldPolicy = {
	my?: FieldPolicy<any> | FieldReadFunction<any>,
	perShare?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SharedReceiptV2KeySpecifier = ('bets' | 'shareInfo' | SharedReceiptV2KeySpecifier)[];
export type SharedReceiptV2FieldPolicy = {
	bets?: FieldPolicy<any> | FieldReadFunction<any>,
	shareInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SportCouponKeySpecifier = ('couponCreator' | 'created' | 'id' | 'modified' | 'payload' | SportCouponKeySpecifier)[];
export type SportCouponFieldPolicy = {
	couponCreator?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamKeySpecifier = ('batchBetAllowed' | 'captain' | 'createdDate' | 'description' | 'gameTypes' | 'id' | 'invitationSetting' | 'isTeamCaptain' | 'location' | 'locationVisible' | 'membershipPolicy' | 'multipleReleasesAllowed' | 'name' | 'ranking' | 'systemsLimit' | 'teamImageUrl' | 'type' | 'vacantMemberships' | 'visible' | TeamKeySpecifier)[];
export type TeamFieldPolicy = {
	batchBetAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	invitationSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	locationVisible?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	multipleReleasesAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	systemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vacantMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	visible?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamMembershipKeySpecifier = ('id' | 'member' | 'memberSince' | 'participatingHistory' | 'roles' | 'teamId' | TeamMembershipKeySpecifier)[];
export type TeamMembershipFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	participatingHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResponseKeySpecifier = ('nbrOfResults' | 'results' | TeamSearchResponseKeySpecifier)[];
export type TeamSearchResponseFieldPolicy = {
	nbrOfResults?: FieldPolicy<any> | FieldReadFunction<any>,
	results?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResultKeySpecifier = ('captainName' | 'gameTypes' | 'maxShareCost' | 'memberLimit' | 'membershipPolicy' | 'minShareCost' | 'numberOfMembers' | 'postalCode' | 'postalTown' | 'ranking' | 'sticky' | 'teamDescription' | 'teamId' | 'teamImageRef' | 'teamName' | 'teamType' | 'updatedDateTimestamp' | TeamSearchResultKeySpecifier)[];
export type TeamSearchResultFieldPolicy = {
	captainName?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	maxShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	memberLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	minShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	sticky?: FieldPolicy<any> | FieldReadFunction<any>,
	teamDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageRef?: FieldPolicy<any> | FieldReadFunction<any>,
	teamName?: FieldPolicy<any> | FieldReadFunction<any>,
	teamType?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedDateTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackKeySpecifier = ('id' | 'name' | TrackKeySpecifier)[];
export type TrackFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Amounts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AmountsKeySpecifier | (() => undefined | AmountsKeySpecifier),
		fields?: AmountsFieldPolicy,
	},
	Application?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ApplicationKeySpecifier | (() => undefined | ApplicationKeySpecifier),
		fields?: ApplicationFieldPolicy,
	},
	BatchBetSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetSummaryKeySpecifier | (() => undefined | BatchBetSummaryKeySpecifier),
		fields?: BatchBetSummaryFieldPolicy,
	},
	BatchBetTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetTeamKeySpecifier | (() => undefined | BatchBetTeamKeySpecifier),
		fields?: BatchBetTeamFieldPolicy,
	},
	BatchBetUploadData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetUploadDataKeySpecifier | (() => undefined | BatchBetUploadDataKeySpecifier),
		fields?: BatchBetUploadDataFieldPolicy,
	},
	BetMeta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetMetaKeySpecifier | (() => undefined | BetMetaKeySpecifier),
		fields?: BetMetaFieldPolicy,
	},
	BetSubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetSubscriptionKeySpecifier | (() => undefined | BetSubscriptionKeySpecifier),
		fields?: BetSubscriptionFieldPolicy,
	},
	BetWithDetails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetWithDetailsKeySpecifier | (() => undefined | BetWithDetailsKeySpecifier),
		fields?: BetWithDetailsFieldPolicy,
	},
	CbsBet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsBetKeySpecifier | (() => undefined | CbsBetKeySpecifier),
		fields?: CbsBetFieldPolicy,
	},
	CbsCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsCouponKeySpecifier | (() => undefined | CbsCouponKeySpecifier),
		fields?: CbsCouponFieldPolicy,
	},
	CbsOffering?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsOfferingKeySpecifier | (() => undefined | CbsOfferingKeySpecifier),
		fields?: CbsOfferingFieldPolicy,
	},
	Contract?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContractKeySpecifier | (() => undefined | ContractKeySpecifier),
		fields?: ContractFieldPolicy,
	},
	Coupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponKeySpecifier | (() => undefined | CouponKeySpecifier),
		fields?: CouponFieldPolicy,
	},
	CouponSelections?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponSelectionsKeySpecifier | (() => undefined | CouponSelectionsKeySpecifier),
		fields?: CouponSelectionsFieldPolicy,
	},
	CreateApplicationResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateApplicationResponseKeySpecifier | (() => undefined | CreateApplicationResponseKeySpecifier),
		fields?: CreateApplicationResponseFieldPolicy,
	},
	Game?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameKeySpecifier | (() => undefined | GameKeySpecifier),
		fields?: GameFieldPolicy,
	},
	GameId?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameIdKeySpecifier | (() => undefined | GameIdKeySpecifier),
		fields?: GameIdFieldPolicy,
	},
	GameInitiatorNotUpdated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorNotUpdatedKeySpecifier | (() => undefined | GameInitiatorNotUpdatedKeySpecifier),
		fields?: GameInitiatorNotUpdatedFieldPolicy,
	},
	GameInitiatorSchedule?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorScheduleKeySpecifier | (() => undefined | GameInitiatorScheduleKeySpecifier),
		fields?: GameInitiatorScheduleFieldPolicy,
	},
	Grading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingKeySpecifier | (() => undefined | GradingKeySpecifier),
		fields?: GradingFieldPolicy,
	},
	GradingCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingCouponKeySpecifier | (() => undefined | GradingCouponKeySpecifier),
		fields?: GradingCouponFieldPolicy,
	},
	HorseCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HorseCouponKeySpecifier | (() => undefined | HorseCouponKeySpecifier),
		fields?: HorseCouponFieldPolicy,
	},
	Leg?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LegKeySpecifier | (() => undefined | LegKeySpecifier),
		fields?: LegFieldPolicy,
	},
	Location?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier),
		fields?: LocationFieldPolicy,
	},
	Marks?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MarksKeySpecifier | (() => undefined | MarksKeySpecifier),
		fields?: MarksFieldPolicy,
	},
	Member?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberKeySpecifier | (() => undefined | MemberKeySpecifier),
		fields?: MemberFieldPolicy,
	},
	MemberTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberTeamKeySpecifier | (() => undefined | MemberTeamKeySpecifier),
		fields?: MemberTeamFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationSettingKeySpecifier | (() => undefined | NotificationSettingKeySpecifier),
		fields?: NotificationSettingFieldPolicy,
	},
	OfferingSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OfferingSummaryKeySpecifier | (() => undefined | OfferingSummaryKeySpecifier),
		fields?: OfferingSummaryFieldPolicy,
	},
	Participant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ParticipantKeySpecifier | (() => undefined | ParticipantKeySpecifier),
		fields?: ParticipantFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Round?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoundKeySpecifier | (() => undefined | RoundKeySpecifier),
		fields?: RoundFieldPolicy,
	},
	Sales?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesKeySpecifier | (() => undefined | SalesKeySpecifier),
		fields?: SalesFieldPolicy,
	},
	SalesDefaults?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesDefaultsKeySpecifier | (() => undefined | SalesDefaultsKeySpecifier),
		fields?: SalesDefaultsFieldPolicy,
	},
	ScheduledRelease?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledReleaseKeySpecifier | (() => undefined | ScheduledReleaseKeySpecifier),
		fields?: ScheduledReleaseFieldPolicy,
	},
	SetGameInitiatorsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetGameInitiatorsResponseKeySpecifier | (() => undefined | SetGameInitiatorsResponseKeySpecifier),
		fields?: SetGameInitiatorsResponseFieldPolicy,
	},
	ShareInfoGrading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoGradingKeySpecifier | (() => undefined | ShareInfoGradingKeySpecifier),
		fields?: ShareInfoGradingFieldPolicy,
	},
	ShareInfoV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoV2KeySpecifier | (() => undefined | ShareInfoV2KeySpecifier),
		fields?: ShareInfoV2FieldPolicy,
	},
	ShareInfoWinnings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoWinningsKeySpecifier | (() => undefined | ShareInfoWinningsKeySpecifier),
		fields?: ShareInfoWinningsFieldPolicy,
	},
	SharedReceiptV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SharedReceiptV2KeySpecifier | (() => undefined | SharedReceiptV2KeySpecifier),
		fields?: SharedReceiptV2FieldPolicy,
	},
	SportCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SportCouponKeySpecifier | (() => undefined | SportCouponKeySpecifier),
		fields?: SportCouponFieldPolicy,
	},
	Team?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamKeySpecifier | (() => undefined | TeamKeySpecifier),
		fields?: TeamFieldPolicy,
	},
	TeamMembership?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamMembershipKeySpecifier | (() => undefined | TeamMembershipKeySpecifier),
		fields?: TeamMembershipFieldPolicy,
	},
	TeamSearchResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResponseKeySpecifier | (() => undefined | TeamSearchResponseKeySpecifier),
		fields?: TeamSearchResponseFieldPolicy,
	},
	TeamSearchResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResultKeySpecifier | (() => undefined | TeamSearchResultKeySpecifier),
		fields?: TeamSearchResultFieldPolicy,
	},
	Track?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackKeySpecifier | (() => undefined | TrackKeySpecifier),
		fields?: TrackFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
