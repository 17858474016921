import {v4 as uuid} from "uuid";
import dayjs from "dayjs";
import {every, head, isEmpty, uniq} from "lodash";
import * as Storage from "@atg-shared/storage";
import {formatCurrency} from "@atg-shared/currency";
import {getApolloClient} from "@atg-tillsammans/apollo-client";
import {
    MUTATION_SAVE_BET_DESCRIPTION,
    MUTATION_UPLOAD_BET_IMAGE,
} from "@atg-tillsammans/graphql/mutation/betMutations.graphql";
import type {
    SaveBetDescriptionMutation,
    SaveBetDescriptionMutationVariables,
    UploadBetImageMutation,
    UploadBetImageMutationVariables,
} from "@atg-tillsammans/graphql/mutation/__generated__/betMutations.graphql.generated";
import type {BetMeta, BetWithDetails} from "@atg-tillsammans/types/generated";
import type {HorseGame} from "@atg-tillsammans/game";
import {getWebsocketIds} from "@atg-frame-shared/push-saga/helpers";
import * as ReceiptUtils from "../../common/utils/receiptUtils";
import type {ReceiptGrading} from "../../common/domain/receiptTypes";
import HorseReceipt from "./HorseReceipt.class";
import {parseDateTimestamp} from "@atg-shared/datetime";

export enum HorseBatchReceiptListenerEvent {
    UPDATE = "UPDATE",
}

export type EmbeddedCouponsSummaryMap = {
    [id: string]: {couponId: string; index: number; stake: number};
};

type HorseBatchReceiptListener = (
    receipt: HorseBatchReceipt,
    event: HorseBatchReceiptListenerEvent,
) => void;

export default class HorseBatchReceipt {
    private game: HorseGame;

    private batchReceipt: BetWithDetails;

    private sneakKey: string;

    public embeddedCouponsSummary: EmbeddedCouponsSummaryMap = {};

    private page = 0;

    private pageSize = 50;

    public teamId: string;

    private listeners: {[listenerId: string]: HorseBatchReceiptListener} = {};

    constructor(
        teamId: string,
        game: HorseGame,
        batchReceipt: BetWithDetails,
        listener?: HorseBatchReceiptListener,
    ) {
        this.teamId = teamId;
        this.game = game;
        this.batchReceipt = batchReceipt;

        if (listener) {
            this.addListener(listener);
        }

        // Fetch first 50 embedded coupons
        this.fetchMoreEmbeddedCoupons();

        // init sneak state
        const sneakKeyWithoutTimestamp = `receipt-sneak-${this.batchReceipt.id}`;

        const existingKeys = Storage.filterByKey(sneakKeyWithoutTimestamp);

        if (isEmpty(existingKeys)) {
            this.sneakKey = `${sneakKeyWithoutTimestamp}-${dayjs().unix()}`;
            Storage.setItem(this.sneakKey, "false");
        } else {
            this.sneakKey = Object.keys(existingKeys)[0];
        }
    }

    addListener(listener: HorseBatchReceiptListener) {
        this.listeners[uuid()] = listener;
    }

    public sneakAll() {
        Storage.setItem(this.sneakKey, "true");
        this.updateState();
    }

    private updateState(event = HorseBatchReceiptListenerEvent.UPDATE) {
        Object.values(this.listeners).forEach((listener) => listener(this, event));
    }

    fetchMoreEmbeddedCoupons() {
        const startIndex = this.page * this.pageSize;
        const endIndex = startIndex + this.pageSize;

        const embeddedCoupons = this.batchReceipt.bet.coupons.slice(startIndex, endIndex);

        this.embeddedCouponsSummary = {
            ...this.embeddedCouponsSummary,
            ...embeddedCoupons.reduce<EmbeddedCouponsSummaryMap>(
                (acc, coupon, index) => ({
                    ...acc,
                    [coupon.id]: {
                        couponId: coupon.id,
                        index: startIndex + index, // keep track of index for easier access later
                        stake: coupon.stake,
                    },
                }),
                {},
            ),
        };

        this.page += 1;

        this.updateState();
    }

    async setImage(image: File) {
        const client = getApolloClient();
        if (!client) return;

        const {data} = await client.mutate<
            UploadBetImageMutation,
            UploadBetImageMutationVariables
        >({
            mutation: MUTATION_UPLOAD_BET_IMAGE,
            variables: {
                input: {
                    betMetaIds: {
                        tsn: this.batchReceipt.bet.tsn,
                        couponId: null,
                    },
                    roundId: `${this.teamId}_${this.game.id}`,
                    websocketIds: getWebsocketIds(),
                },
                image,
            },
        });

        if (!data?.uploadBetImage) return;

        this.setBetMeta(data.uploadBetImage);
    }

    async setDescription(description: string) {
        const client = getApolloClient();
        if (!client) return;

        const {data} = await client.mutate<
            SaveBetDescriptionMutation,
            SaveBetDescriptionMutationVariables
        >({
            mutation: MUTATION_SAVE_BET_DESCRIPTION,
            variables: {
                input: {
                    betMetaIds: {
                        tsn: this.batchReceipt.bet.tsn,
                        couponId: null,
                    },
                    description,
                    roundId: `${this.teamId}_${this.game.id}`,
                    websocketIds: getWebsocketIds(),
                },
            },
        });

        if (!data?.saveBetDescription) return;

        this.setBetMeta(data.saveBetDescription);
    }

    getEmbeddedReceipt(embeddedCouponId: string) {
        const couponSummary = this.embeddedCouponsSummary[embeddedCouponId];
        if (!couponSummary) return null;

        const {bet, amounts} = this.batchReceipt;

        // Get the whole embedded coupon object
        const embeddedCoupon = bet.coupons[couponSummary.index];

        // Get gradings for the right coupon
        const embeddedCouponGrading = bet.grading?.coupons
            ? bet.grading?.coupons[couponSummary.index]
            : null;

        return new HorseReceipt(
            this.game,
            {
                ...this.batchReceipt,
                amounts: {
                    ...amounts,
                    totalCost: embeddedCoupon.stake,
                },
                bet: {
                    ...bet,
                    stake: embeddedCoupon.stake,
                    coupons: [embeddedCoupon],
                    numberOfSystems: embeddedCoupon.numberOfSystems ?? 1,
                    grading: embeddedCouponGrading
                        ? {
                              qualifyingUnits: embeddedCouponGrading.qualifyingUnits,
                              dividend: embeddedCouponGrading.dividend,
                              coupons: [embeddedCouponGrading],
                          }
                        : null,
                },
            },
            undefined,
            true,
        );
    }

    getEmbeddedReceiptNumber(embeddedCouponId: string | null) {
        if (!embeddedCouponId) return 1;
        const couponSummary = this.embeddedCouponsSummary[embeddedCouponId];
        return couponSummary ? couponSummary.index + 1 : 1;
    }

    setBetMeta(betMeta: BetMeta) {
        this.batchReceipt = {
            ...this.batchReceipt,
            betMeta,
        };

        this.updateState();
    }

    get sneak() {
        const sneakState = Storage.getItem(this.sneakKey);

        return {
            isCompleted: !sneakState ? false : sneakState === "true",
            corrects: this.numberOfCorrects,
        };
    }

    get numberOfCorrects() {
        const {grading} = this;

        if (!grading) return 0;

        if (grading.splits["8"]?.units) return 8;
        if (grading.splits["7"]?.units) return 7;
        if (grading.splits["6"]?.units) return 6;
        if (grading.splits["5"]?.units) return 5;

        return 0;
    }

    get hasImage() {
        return !!this.batchReceipt.betMeta?.imageRef;
    }

    get imageRef() {
        return this.batchReceipt.betMeta?.imageRef ?? null;
    }

    get hasDescription() {
        return !!this.batchReceipt.betMeta?.description;
    }

    get description() {
        return this.batchReceipt.betMeta?.description ?? null;
    }

    get id() {
        return this.batchReceipt.bet.tsn;
    }

    get reducedId() {
        return this.batchReceipt.betMeta?.couponId;
    }

    get totalCost() {
        return this.batchReceipt.amounts.totalCost || 0;
    }

    get offering() {
        return this.batchReceipt.offering;
    }

    get hasOutcomeInAllRaces() {
        return every(this.game.races, (race) => {
            const pool = race.pools && race.pools[this.game.type];

            return pool ? !isEmpty(pool.result?.winners) : false;
        });
    }

    get tsn() {
        const {tsn} = this.batchReceipt.bet;

        if (!tsn || tsn === "") return null;

        return [
            tsn.substring(0, 4),
            tsn.substring(4, 8),
            tsn.substring(8, 12),
            tsn.substring(12),
        ].join(" ");
    }

    get grading(): ReceiptGrading | null {
        return ReceiptUtils.getReceiptGrading(this.batchReceipt.bet.grading);
    }

    get formattedTotalCost() {
        return formatCurrency(this.batchReceipt.amounts.totalCost ?? 0);
    }

    get startTime() {
        return head(this.game.races)?.startTime;
    }

    get formattedStartTime() {
        const {startTime} = this;
        return startTime ? parseDateTimestamp(startTime).format("D MMM [kl.] LT") : "-";
    }

    get formattedTracks() {
        return uniq(this.game.races.map((race) => race.track.name)).join(" & ");
    }

    get placedAt() {
        return this.batchReceipt.bet.placedAt;
    }

    get formattedPlacedAt() {
        return dayjs(this.batchReceipt.bet.placedAt).format("L LT");
    }

    get numberOfEmbeddedReceipts() {
        return Object.keys(this.batchReceipt.bet.coupons).length;
    }

    get numberOfSystems() {
        return this.batchReceipt.bet.numberOfSystems;
    }

    get hasListener() {
        return !isEmpty(this.listeners);
    }
}
