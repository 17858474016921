import {v4 as uuid} from "uuid";
import dayjs from "dayjs";
import {head, isEmpty, some, uniq} from "lodash";
import {t} from "@lingui/macro";
import * as Storage from "@atg-shared/storage";
import {parseDateTimestamp} from "@atg-shared/datetime";
import {formatCurrency} from "@atg-shared/currency";
import type {HorseGame} from "@atg-tillsammans/game";
import type {BetWithDetails as HorseReceiptType} from "@atg-tillsammans/types/generated";
import {CbsBetStatus} from "@atg-tillsammans/types";
import {getReceiptGrading} from "../../common/utils/receiptUtils";
import type {ReceiptGrading, ReceiptInformation} from "../../common/domain/receiptTypes";
import type {
    HorseReceiptCouponSelections,
    HorseReceiptLeg,
    HorseReceiptLegSummary,
    HorseReceiptSneakState,
} from "./horseReceiptTypes";

export enum HorseReceiptListenerEvent {
    UPDATE = "UPDATE",
}

type HorseReceiptListener = (
    receipt: HorseReceipt,
    event: HorseReceiptListenerEvent,
) => void;

export default class HorseReceipt implements ReceiptInformation {
    public game: HorseGame;

    private receipt: HorseReceiptType;

    private sneakKey: string;

    public sneakDisabled: boolean;

    private listeners: {[listenerId: string]: HorseReceiptListener} = {};

    constructor(
        game: HorseGame,
        receipt: HorseReceiptType,
        listener?: HorseReceiptListener,
        sneakDisabled = false,
    ) {
        this.game = game;
        this.receipt = HorseReceipt.stripTypenames(receipt);
        this.sneakKey = `receipt-sneak-${this.receipt.bet.tsn}-${dayjs().unix()}`;

        if (listener) {
            this.addListener(listener);
        }

        this.sneakDisabled = sneakDisabled;

        if (sneakDisabled) return;

        // init sneak state if not disabled
        const sneakKeyWithoutTimestamp = `receipt-sneak-${this.receipt.bet.tsn}`;
        const existingKeys = Storage.filterByKey(sneakKeyWithoutTimestamp);

        if (isEmpty(existingKeys)) {
            this.sneakKey = `${sneakKeyWithoutTimestamp}-${dayjs().unix()}`;

            const sneakState = JSON.stringify(
                game.races.reduce(
                    (map, _, index) => ({
                        ...map,
                        [index + 1]: false,
                    }),
                    {},
                ),
            );

            Storage.setItem(this.sneakKey, sneakState);
        } else {
            this.sneakKey = Object.keys(existingKeys)[0];
        }
    }

    static stripTypenames(receipt: HorseReceiptType): HorseReceiptType {
        return JSON.parse(
            JSON.stringify(receipt, (key, value) =>
                key === "__typename" ? undefined : value,
            ),
        );
    }

    private getSneakStateFromLocalStorage() {
        return Storage.getObject<HorseReceiptSneakState>(this.sneakKey);
    }

    private setSneakStateToLocalStorage(sneakState: HorseReceiptSneakState) {
        Storage.setObject(this.sneakKey, sneakState);
        this.updateState();
    }

    addListener(listener: HorseReceiptListener) {
        this.listeners[uuid()] = listener;
    }

    removeListeners() {
        Object.keys(this.listeners).forEach(
            (listenerId) => delete this.listeners[listenerId],
        );
    }

    getReservesForLeg(legIndex: number): number[] {
        const {coupons} = this.receipt.bet;

        if (coupons.length === 0) return [];

        const selections = coupons[0].selections as HorseReceiptCouponSelections;

        const leg = selections.legs[legIndex];

        if (!leg) return [];

        const {reserves} = leg;
        return reserves && reserves.length > 0 ? reserves.map((reserve) => reserve) : [];
    }

    /**
     * Get effective reserves for a leg. These can be 0...N of reserves based the how many
     * selections was scratched in the leg.
     *
     * @param legNumber
     * @returns
     */
    private getEffectiveReservesForLeg(legNumber: number): number[] {
        const legsSummary = this.offering.summary.legs as Record<
            number,
            HorseReceiptLegSummary
        >;
        const scratched = legsSummary[legNumber].scratched ?? [];

        const scratchedMarks = this.getMarksForLeg(legNumber).filter((mark) =>
            scratched.includes(mark),
        );

        if (scratchedMarks.length === 0) return [];

        const effectiveMarks = this.effectiveMarksByLeg[legNumber];

        return effectiveMarks
            ? effectiveMarks.slice(
                  Math.max(effectiveMarks.length - scratchedMarks.length, 0),
              )
            : [];
    }

    getMarksForLeg(legIndex: number): number[] {
        const selections = this.receipt.bet.coupons[0]
            .selections as HorseReceiptCouponSelections;

        const leg = selections.legs[legIndex];

        if (!leg) return [];

        const {marks} = leg;
        return marks && marks.length > 0 ? marks.map((mark) => mark) : [];
    }

    isStartSelectedAsReserveInLeg(legIndex: number, startNumber: number) {
        const reserves = this.getReservesForLeg(legIndex);
        return reserves && reserves.indexOf(startNumber) > -1;
    }

    getReserveNumber(legIndex: number, startNumber: number) {
        const reserves = this.getReservesForLeg(legIndex);
        return reserves ? `${reserves.indexOf(startNumber) + 1}` : "";
    }

    getFormattedReserves(legNumber: number) {
        return this.getReservesForLeg(legNumber).join(", ");
    }

    sneakLegs(legNumbers: number[]) {
        const sneakState = this.getSneakStateFromLocalStorage();
        if (!sneakState) return;

        legNumbers.forEach((legNumber) => {
            sneakState[legNumber] = true;
        });

        this.setSneakStateToLocalStorage(sneakState);
    }

    sneakAllWithOutcomes() {
        this.sneakLegs(
            Object.values(this.legs).reduce<number[]>(
                (legNumbers, leg) =>
                    leg.hasOutcome ? [...legNumbers, leg.legNumber] : legNumbers,
                [],
            ),
        );
    }

    isStartSelectedInLeg(legNumber: number, startNumber: number) {
        // Note: If receipt is unvierified return false since there are not marks available in the receipt.
        if (this.isUnverified) return false;

        return !!this.legs[legNumber].marks.find((mark) => mark.mark === startNumber);
    }

    setGame(value: HorseGame) {
        this.game = value;
        this.updateState();
    }

    private isLegSneaked(legNumber: number) {
        const sneakState = this.getSneakStateFromLocalStorage();

        if (!sneakState) return false;
        return sneakState[legNumber] ?? false;
    }

    private updateState(event = HorseReceiptListenerEvent.UPDATE) {
        Object.values(this.listeners).forEach((listener) => listener(this, event));
    }

    get id() {
        return this.receipt.bet.tsn;
    }

    get data() {
        return this.receipt;
    }

    get sneak() {
        const sneakState = this.getSneakStateFromLocalStorage();

        if (!sneakState)
            return {count: 0, corrects: 0, isStarted: false, isCompleted: false};

        const count = Object.values(sneakState).reduce(
            (sneakCount, sneaked) => (sneaked ? sneakCount + 1 : sneakCount),
            0,
        );

        return {
            count,
            corrects: Object.values(this.legs).reduce(
                (acc, leg) => (some(leg.marks, (mark) => mark.isCorrect) ? acc + 1 : acc),
                0,
            ),
            isStarted: count > 0,
            isCompleted: Object.values(sneakState).length === count,
        };
    }

    get grading(): ReceiptGrading | null {
        return getReceiptGrading(this.receipt.bet.grading);
    }

    get effectiveMarksByLeg(): Record<number, number[]> {
        const gradings = head(this.receipt.bet.grading?.coupons ?? []);
        return gradings ? (gradings.effectiveMarks as Record<number, number[]>) : {};
    }

    get legs(): Record<string, HorseReceiptLeg> {
        const {bet, offering} = this.receipt;

        const gradings = head(bet.grading?.coupons ?? []);
        const legSummary = offering.summary.legs as Record<
            number,
            HorseReceiptLegSummary
        >;

        const effectiveMarks = this.effectiveMarksByLeg;

        // TODO: Perhaps return null here and show an error in the receipt component.
        if (!gradings || !legSummary) return {};

        const legNumbers: number[] = Object.keys(effectiveMarks).map(Number);

        return legNumbers.reduce<Record<number, HorseReceiptLeg>>(
            (acc, legNumber, index) => {
                const {scratched, status} = legSummary[legNumber];
                const race = this.game.races[legNumber - 1];
                const pool = race?.pools ? race.pools[this.game.type] : undefined;
                const outcome = pool ? pool.result?.winners ?? [] : [];
                const isSneaked = this.isLegSneaked(legNumber);
                const marks = this.getMarksForLeg(legNumber);
                const reserves = this.getEffectiveReservesForLeg(legNumber);
                const isCanceled = status === "CANCELED";

                const resolvCorrect = (mark: number) =>
                    isSneaked && (isCanceled || outcome.includes(mark));

                acc[legNumber] = {
                    legNumber,
                    hasOutcome: !this.sneakDisabled && (!isEmpty(outcome) || isCanceled),
                    outcomes: outcome,
                    isSneaked,
                    isCanceled,
                    marks: [
                        ...marks.map((mark) => {
                            const start = this.game.races[index].starts[mark - 1];

                            /**
                             * Note:
                             * start can be undefined in case of a filebet when a start has been selected
                             * that doesn't exist. In this case we mark it as a reserve. The same thing
                             * goes for reserves below.
                             */
                            return start
                                ? {
                                      mark,
                                      horse: start.horse.name,
                                      isForeignOwned: start.horse.foreignOwned === true,
                                      nationality: start.horse.nationality,
                                      isCorrect: resolvCorrect(mark),
                                      isScratched: scratched.includes(mark),
                                      isReserve: false,
                                  }
                                : {
                                      mark,
                                      horse: "",
                                      isForeignOwned: false,
                                      nationality: "",
                                      isCorrect: false,
                                      isScratched: true,
                                      isReserve: false,
                                  };
                        }),
                        ...reserves.map((mark) => {
                            const start = this.game.races[index].starts[mark - 1];
                            return start
                                ? {
                                      mark,
                                      horse: this.game.races[index].starts[mark - 1].horse
                                          .name,
                                      isForeignOwned: start.horse.foreignOwned === true,
                                      nationality: start.horse.nationality,
                                      isCorrect: resolvCorrect(mark),
                                      isScratched: scratched.includes(mark),
                                      isReserve: true,
                                  }
                                : {
                                      mark,
                                      horse: "",
                                      isForeignOwned: false,
                                      nationality: "",
                                      isCorrect: false,
                                      isScratched: true,
                                      isReserve: true,
                                  };
                        }),
                    ],
                };

                return acc;
            },
            {},
        );
    }

    get numberOfLegsWithOutcome() {
        return Object.entries(this.legs).filter(([_, v]) => v.hasOutcome).length;
    }

    get isActive() {
        return this.receipt.bet.status === CbsBetStatus.ACTIVE;
    }

    // The receipt get status GRADED when the receipt is GRADED and the shared bet winnings has been distributed
    get isGraded() {
        return this.receipt.bet.status === CbsBetStatus.GRADED;
    }

    // The receipt has Graded data when the receipt is GRADED, which is before the share winnings is distributed
    get hasGradedData() {
        return !!getReceiptGrading(this.receipt.bet.grading);
    }

    get isUnverified() {
        return this.receipt.bet.status === CbsBetStatus.UNVERIFIED;
    }

    get rowPrice() {
        return this.receipt.offering.unitStake;
    }

    get combinations() {
        return Object.values(this.effectiveMarksByLeg).reduce(
            (acc, leg) => acc * leg.length,
            1,
        );
    }

    get hasListener() {
        return !isEmpty(this.listeners);
    }

    get numberOfSystems() {
        return this.receipt.bet.numberOfSystems;
    }

    get stake() {
        return this.receipt.bet.stake;
    }

    get totalCost() {
        return this.receipt.amounts.totalCost || 0;
    }

    get offering() {
        return this.receipt.offering;
    }

    get tsn() {
        const {tsn} = this.receipt.bet;

        if (!tsn || tsn === "") return null;

        return [
            tsn.substring(0, 4),
            tsn.substring(4, 8),
            tsn.substring(8, 12),
            tsn.substring(12),
        ].join(" ");
    }

    get fee() {
        return this.receipt.amounts.sellingFee || 0;
    }

    get feeExists(): boolean {
        return this.fee > 0;
    }

    get formattedFee() {
        return formatCurrency(this.fee);
    }

    get startTime() {
        return head(this.game.races)?.startTime;
    }

    get formattedStartTime() {
        const {startTime} = this;
        return startTime ? parseDateTimestamp(startTime).format("D MMM [kl.] LT") : "-";
    }

    get formattedTracks() {
        return uniq(this.game.races.map((race) => race.track.name)).join(" & ");
    }

    get placedAt() {
        return this.receipt.bet.placedAt;
    }

    get formattedPlacedAt() {
        return parseDateTimestamp(this.placedAt).format("L LT");
    }

    get formattedPlacedAtTime() {
        return parseDateTimestamp(this.placedAt).format("LT");
    }

    get formattedStake() {
        return formatCurrency(this.receipt.bet.stake);
    }

    get formattedStakeFormula() {
        return `${this.combinations}x${this.numberOfSystems}x${this.formattedRowPrice}`;
    }

    get formattedTotalCost() {
        return formatCurrency(this.receipt.amounts.totalCost ?? 0);
    }

    get formattedRowPrice() {
        return formatCurrency(this.rowPrice);
    }

    get formattedCombinations() {
        return Object.values(this.effectiveMarksByLeg)
            .map((marks) => marks.length)
            .join("x");
    }

    isRaceUpcoming(legNumber: number) {
        const {races} = this.game;

        const legIsUpcoming = races[legNumber - 1].status === "upcoming";
        const hasOngoingRace = races.find(
            (race) => race.status === "results" || race.status === "ongoing",
        );

        return (hasOngoingRace && legIsUpcoming) || false;
    }

    raceStartTime(legNumber: number) {
        const {startTime} = this.game.races[legNumber - 1];
        return startTime
            ? `${t({id: "common.start", message: "Start"})} ${parseDateTimestamp(
                  startTime,
              ).format("LT")}`
            : "-";
    }
}
