import type {GameID} from "@atg-tillsammans-shared/types";
import type {Member} from "../generated";
import type {TeamID} from "./team";

export enum RoundState {
    OPEN = "OPEN",
    SOLD_OUT = "SOLD_OUT",
    CLOSED = "CLOSED",
    PLACED = "PLACED",
    NOT_PLACED = "NOT_PLACED",
    INACTIVE = "INACTIVE",
    PAUSED = "PAUSED",
}

export enum RoundBetMethod {
    VXY = "VXY",
    HARRY = "HARRY",
}

export type RoundSales = {
    deadline: string;
    price: number;
    buyLimit: number;
    buyLimitPerPerson: number;
    totalSalesAmount: number;
    refundAmount: number;
};

export type RoundGameInitiator = Member;

export type Round = {
    id: string;
    teamId: TeamID;
    gameId: GameID;
    state: RoundState;
    description?: string;
    sales?: RoundSales;
    betMethod?: RoundBetMethod;
    isGameInitiator: boolean;
    gameInitiator: RoundGameInitiator;
};
