import {createSelector} from "reselect";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import type {GlobalState as State} from "./teamTypes";

export const teamState = (state: State) => state.teams;
export const teamListState = (state: State) => teamState(state).list;

export const isTeamsLoaded = (state: State) => teamState(state).list.items !== null;
export const isTeamsNotLoaded = (state: State) => !isTeamsLoaded(state);

export const getTeams = createSelector(teamListState, ({items}) => items ?? []);

export const isTeamMember = (state: State, teamId?: string) => {
    if (!teamId) return false;
    const teamIds = getTeams(state).map(({id}) => id);
    return teamIds.includes(teamId);
};

export const getMemberTeam = (state: State, teamId: string) => {
    const teams = getTeams(state);
    const team = teams.find((t) => `${t.id}` === `${teamId}`);
    if (!team) {
        return null;
    }

    return team;
};

const getTeam = (state: State) => teamState(state).team;

export const getTeamId = (state: State): string | null => getTeam(state).teamId;

export const getTeamMembers = (state: State) => teamState(state).teamMembers.data;

const imageUploadState = (state: State) => teamState(state).imageUploadFlow;
export const isImageUploadLoading = (state: State) => imageUploadState(state).loading;
export const getImageUploadError = (state: State) => imageUploadState(state).error;

const revokeAppliciationState = (state: State) => teamState(state).revokeApplication;
export const getRevokeApplicationStatus = createSelector(
    revokeAppliciationState,
    fetchSelectors.getLoadingStatus,
);

export const hasNewMessages = (state: State): boolean => {
    const teamId = getTeamId(state);
    const memberTeam = teamId ? getMemberTeam(state, teamId) : null;
    const unreadMessages = memberTeam ? memberTeam.unreadComments : null;
    return unreadMessages ? unreadMessages > 0 : false;
};
