import {fromPromise} from "@apollo/client";
import type {ApolloLink} from "@apollo/client";
// @ts-ignore
import {createUploadLink} from "apollo-upload-client";
import type {Logger} from "loglevel";
import {onError} from "@apollo/client/link/error";
import {includes, some} from "lodash";
import type {Dispatch} from "redux";
import {bffAuthentication} from "@atg-shared/auth/domain/authActions";
import {fetchAuthorized} from "@atg-shared/auth";
import type {AtgResponse} from "@atg-shared/fetch-types";
import {addAuthHeaders, fetchFileUpload, pureFetch} from "@atg-shared/fetch";
import type {GraphQLError} from "graphql";

interface AccessTokenResult {
    token: string;
    hadToLogin: boolean;
}

export const errorLink = (dispatch: Dispatch, logger: Logger): ApolloLink =>
    onError(({graphQLErrors, networkError, operation, forward}) => {
        if (graphQLErrors) {
            const hasAuthError = some(graphQLErrors, (error: GraphQLError) =>
                includes(error?.extensions?.errorCode, "ACCESS_DENIED"),
            );

            if (hasAuthError) {
                const promise = new Promise<AccessTokenResult>((resolve, reject) => {
                    dispatch(bffAuthentication(resolve, reject));
                });

                return fromPromise(promise).flatMap(
                    ({hadToLogin, token}: AccessTokenResult) => {
                        if (!hadToLogin) {
                            operation.setContext({
                                headers: {
                                    ...operation.getContext().headers,
                                    authorization: `Bearer ${token}`,
                                },
                            });
                        }

                        return forward(operation);
                    },
                );
            }
        }

        if (graphQLErrors || networkError) {
            logger.warn("apolloClientError", {graphQLErrors, networkError});
        }

        return undefined;
    });

const isNewAtgApi = (uri: string) => uri.includes("https://api.");

export const uploadLink = () =>
    createUploadLink({
        uri: "/services/tokenized-proxy/team/api/graphql",
        fetch: async (uri: string, options: RequestInit): Promise<Response> => {
            // Remove unnecessary request headers which are set in atgFetch().
            const optionsWithoutHeaders = options;
            delete optionsWithoutHeaders.headers;
            if (options.body instanceof FormData) {
                return fetchAuthorized(
                    uri,
                    {
                        ...optionsWithoutHeaders,
                        method: "POST",
                        headers: addAuthHeaders({}),
                    },
                    {
                        memberFlowEnabled: false,
                    },
                    fetchFileUpload,
                ).then(
                    (response) =>
                        new Response(JSON.stringify(response.data), {
                            status: response.meta.code,
                            statusText: response.meta.statusText,
                        }),
                );
            }

            function mapResponse(response: AtgResponse<unknown>): Response {
                return new Response(JSON.stringify(response.data), {
                    status: response.meta.code,
                    statusText: response.meta.statusText,
                });
            }

            // For public api endpoint (e.g. https://api.atg.se/team)
            if (isNewAtgApi(uri)) {
                return pureFetch(uri, {
                    body: optionsWithoutHeaders.body,
                    method: optionsWithoutHeaders.method,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(mapResponse);
            }

            return fetchAuthorized(uri, optionsWithoutHeaders, {
                memberFlowEnabled: false,
            }).then(mapResponse);
        },
    });
