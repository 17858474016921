import root from "window-or-global";
import system from "@atg-shared/system";

export const SEARCH_ENVIRONMENT_URL =
    (typeof root !== "undefined" && root.clientConfig && root.clientConfig.searchUrl) ||
    "/search";

export const environment = root?.clientConfig?.env ?? "qa";
export const atgApiSubdomain = `api${environment !== "prod" ? `.${environment}.hh` : ""}`;

export const USER_SERVICE_URL = "/services/v1/user";
export const ACCOUNT_HISTORY_SERVICE_URL =
    "/services/tokenized-proxy/account-history/api/v1/account/transactions";
export const INFO_SERVICE_URL = "/services/v1/info";
export const PAYMENT_URL = "/services/tokenized-proxy/payment/api/v1";
export const PAYMENT_URL_NEW = "/services/tokenized-proxy/payment/api/v2";
export const SUBSCRIPTION_SERVICE_URL = "/services/v1/subscriptions";
export const USER_COUPON_SERVICE_URL = `${USER_SERVICE_URL}/coupons`;
export const PUBLIC_COUPON_SERVICE_URL = "/services/v1/coupons";
export const COUPON_SERVICE_URL =
    "/services/tokenized-proxy/horse-coupons/api/v1/coupons";
export const LOG_SERVICE_URL = "/services/v1/logs";
export const CUSTOMER_SERVICE_URL = "/services/v1/customer";
export const MEMBER_SERVICE_URL = "/services/v1/member";
export const NEW_MEMBER_SERVICE_URL = "/services/tokenized-proxy/member";
export const BET_SERVICE_URL = "/services/v1/bets";
export const BET_GALLERY_SERVICE_URL = "/services/v1/betGallery";
export const USER_RECOVERY_URL = "/services/tokenized-proxy/member-auth/api/v1/recovery";
export const LEGACY_PRODUCT_SERVICE_URL = "/services/v1/products";
export const TEAM_SERVICE_LEGACY_URL = "/services/v1/teams";
export const TEAM_SERVICE_API_URL = "/services/tokenized-proxy/team/api";
export const TEAM_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v1/teams`;
/**
 * @deprecated Legacy Tillsmmmans public api
 */
export const TEAM_SERVICE_PUBLIC_URL = "/services/api/v1/teams";
export const TEAM_CHAT_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v2/chat`;
/**
 * @deprecated Legacy Tillsmmmans public api
 */
export const LEGACY_TEAM_CHAT_PUBLIC_SERVICE_URL = "/services/api/v2/chat";
export const TEAM_CHAT_PUBLIC_SERVICE_URL = "/team/api/v2/chat";
export const TEAM_SUBSCRIBE_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v1/subscriptions`;
export const TEAM_PERSONALIZATION_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v1/personalization`;
export const TEAM_MEMBER_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v1/member`;
export const TEAM_CLIENT_LOG_SERVICE_URL = `${TEAM_SERVICE_API_URL}/v1/logs`;
export const SHOP_CHAT_SERVICE_URL = "/services/tokenized-proxy/shopshare/api/v1/chat";
export const SHOP_CHAT_SERVICE_PUBLIC_URL = "/services/shop-share/v1/chat";
export const SUBMAN_SERVICE_URL = "/services/tokenized-proxy/subman/api/v1";
export const SHOP_SERVICE_URL = "/services/v1/shops";
export const SHOP_SHARE_SERVICE_URL = "/services/shop-share/v1";
export const SHOP_SHARE_SERVICE_URL_TOKENIZED =
    "/services/tokenized-proxy/shopshare/api/v1";
export const REDUCED_BET_METADATA =
    "/services/tokenized-proxy/horse-reduction-calculator/api/v1/reduce/metadata";
export const MEMBER_AUTH_URL = "/services/tokenized-proxy/member-auth/api/v1/member";
export const MEMBER_SERVICE_V3_URL = "/services/tokenized-proxy/member/api/v3/member";
export const ATG_CARD_V3_URL = "/services/tokenized-proxy/member/api/v3/atg-card";
export const BET_HISTORY_GRADING =
    "/services/tokenized-proxy/service-horse-bet-history/api/v1/grading";
export const BET_HISTORY_SINGLE_BETS_TODAY =
    "/services/tokenized-proxy/service-horse-bet-history/api/v1/single-bets/today";
export const BET_HISTORY_SINGLE_BETS_RESPONSES_TODAY =
    "/services/tokenized-proxy/service-horse-bet-history/api/v1/single-bet-responses/today";
export const REDUCED_BET_GRADING =
    "/services/tokenized-proxy/horse-reduced-bet-grading/api/public/v1/grading/reduced-bet";
export const RGS_SERVICE_URL = "/services/tokenized-proxy/rgs/api/v1/rg";
export const KYC_SERVICE_URL = "/services/tokenized-proxy/kyc/api/v1/questionnaire";
export const VARENNE_COUPON_TEAMS_URL =
    "/services/tokenized-proxy/couponteam/api/v1/coupon-team";

export const getAtgUserServiceUrl = () => {
    let ATG_SERVICE_URL = "";

    const isTillsammansDomain = root.location.host.includes("tillsammans");

    if (isTillsammansDomain) {
        if (environment === "prod") {
            ATG_SERVICE_URL = "https://www.atg.se";
        } else if (environment === "test1") {
            ATG_SERVICE_URL = "https://test1.dev.atg.se";
        } else {
            ATG_SERVICE_URL = environment ? `https://www.${environment}.hh.atg.se` : "";
        }
    }

    return ATG_SERVICE_URL + USER_SERVICE_URL;
};

export const getCurityConfig = () => {
    const tokenHandlerUrl =
        process.env.NODE_ENV !== "test"
            ? root.clientConfig.curity.tokenHandlerURI
            : "https://login.qa.hh.atg.se/v1";
    const logoutServiceUrl =
        process.env.NODE_ENV !== "test"
            ? `${
                  root.clientConfig.curity.tokenHandlerURI
              }/logout?caller=${system.getSystemName()}`
            : `https://login.qa.hh.atg.se/v1/logout?caller=${system.getSystemName()}`;

    const url =
        process.env.NODE_ENV !== "test"
            ? root.clientConfig.curity.uri
            : "https://iam.qa.hh.atg.se";

    const tillsammansWicketLogoutUrl =
        process.env.NODE_ENV !== "test"
            ? root.clientConfig.curity.tillsammansWicketLogoutURI
            : "TILLSAMMANS_WICKET_LOGOUT_MOCK_URL";

    return {
        logoutServiceUrl,
        tillsammansWicketLogoutUrl,
        tokenHandlerUrl,
        url,
    };
};

export const getTillsammansCurityConfig = () => {
    const tokenHandlerUrl =
        process.env.NODE_ENV !== "test"
            ? root.clientConfig.curity.tokenHandlerURI
            : "https://login.qa.hh.atg.se/v1";
    const logoutServiceUrl =
        process.env.NODE_ENV !== "test"
            ? `${
                  root.clientConfig.curity.tokenHandlerURI
              }/logout?caller=${system.getSystemName()}`
            : `https://login.qa.hh.atg.se/v1/logout?caller=${system.getSystemName()}`;
    const url =
        process.env.NODE_ENV !== "test"
            ? root.clientConfig.curity.uri
            : "https://iam.qa.hh.atg.se";
    return {
        logoutServiceUrl,
        tokenHandlerUrl,
        url,
    };
};

// CMS
export const CMS_SERVICE_URL = "/services/cms";
export const STARTPAGE_CMS_PAGE_PATH = "/site/atg.se/current/startpage";

// PTS
export const PTS_SERVICE_URL = "/services/tokenized-proxy/pts";

// RacingInfo
export const PRODUCT_SERVICE_URL = "/services/racinginfo/v1/api/products";
export const HORSE_SERVICE_URL = "/services/racinginfo/v1/api/horses";
export const HARRY_SERVICE_URL = "/services/racinginfo/v1/api/harry";
export const CALENDAR_SERVICE_URL = "/services/racinginfo/v1/api/calendar";
export const GAME_SERVICE_URL = "/services/racinginfo/v1/api/games";
export const TRACK_SERVICE_URL = "/services/racinginfo/v1/api/tracks";
export const RACE_SERVICE_URL = "/services/racinginfo/v1/api/races";
export const POOL_SERVICE_URL = "/services/racinginfo/v1/api/pools";
export const LIVE_STREAM_SERVICE_URL = "/services/racinginfo/v1/api/livestreams";
export const HIGHLIGHT_SERVICE_URL = "/services/racinginfo/v1/api/highlights";
export const HOTGAME_SERVICE_URL = "/services/racinginfo/v1/api/hotgames";
export const MEDIA_SERVICE_URL = "/services/racinginfo/v1/api/media";
export const SIMPLE_RESULTS_URL = "/services/racinginfo/v1/api/results";
export const SILKS_SERVICE_URL = "/services/racinginfo/v1/api/silks";

// Elastic search services
export const SHOPS_SEARCH_SERVICE_URL = `${SEARCH_ENVIRONMENT_URL}/shops`;
export const VMV75_SEARCH_SERVICE_URL = `${SEARCH_ENVIRONMENT_URL}/vmiv75`;

// Vm i V75 Campaign
export const DEPRECATED_VMV75_SERVICE_URL = "/services/v1/campaign/vmv75";
export const VMV75_SERVICE_URL = "/services/tokenized-proxy/horse-champions/api/v1";
export const VMV75_SERVICE_URL_PUBLIC = "horse-champions/api-public/v1";

// Unauthorized Login attempt
export const UNAUTHORIZED_LOGIN_SERVICE_URL =
    "/services/tokenized-proxy/member-auth/api/v1/member/login/latest";

// Member segmentation
export const MEMBER_SEGMENTATION_SERVICE_URL =
    "/services/tokenized-proxy/member-segmentation/api/v1/segmentation";

// RGS
export const RGS_NET_LOSS_LIMITS_SERVICE_URL = `${RGS_SERVICE_URL}/netlosslimit`;
export const RGS_SELF_EXCLUSION_SERVICE_URL = `${RGS_SERVICE_URL}/selfexclusion`;

// Big9
export const BIG9_COUPON_API_URL = "/services/tokenized/sports-coupon/api/v1/coupons";
export const BIG9_OFFERING_API_URL = "/services/sports-info-offering/api/v1/offering";
export const BIG9_PLACE_BET_API_URL = "/services/tokenized/sports-bet/api/v1/bet";
export const BIG9_PLACE_BET_V2_API_URL = "/services/tokenized/sports-bet/api/v2/bet";
export const BIG9_PLACE_SELL_BET_V2_API_URL =
    "/services/tokenized/sports-bet/api/v2/bet/sell-bet";
export const BIG9_RECEIPTS_API_URL = "/services/tokenized/sports-bet/api/v1/receipts";
export const BIG9_RECEIPTS_V2_API_URL = "/services/tokenized/sports-bet/api/v2/receipts";

// Big9 Partner Reduced Betting
export const BIG9_REDUCED_BETTING_URL = "/services/tokenized/reducedbet/api/v1/coupons";
export const BIG9_PLACE_REDUCED_BET_V2_API_URL =
    "/services/tokenized/sports-bet/api/v2/bet/reduced";

// Inbox
export const INBOX_SERVICE_URL = "/services/tokenized-proxy/customer-inbox/api/v1";

// Customer transactions
export const CUSTOMER_TRANSACTIONS_URL =
    "/services/tokenized-proxy/customer-transactions/api/v1";

// RCS, Responsible communication Services
export const RCS_SERVICE_URL = "/services/tokenized-proxy/rcs/api/v1";
