import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {deprecated_MemberTeam, TeamMembers} from "@atg-tillsammans/types";
import type {MemberTeam} from "@atg-tillsammans/types/generated";
import {TeamApiUtils} from "@atg-tillsammans/team-utils";
import type {ImageFilePayload, ImageUrl} from "atg-ui-image/domain/image";
import * as teamApi from "./teamApi";

export const PURCHASE_SHARE_ACTION = "PURCHASE_SHARE_ACTION";
export const WRITE_COMMENT_ACTION = "WRITE_COMMENT_ACTION";

export const TEAM_MEMBER_JOINED = "teams/TEAM_MEMBER_JOINED";

export const REQUEST_TEAMS = "teams/REQUEST_TEAMS";
export const RECEIVE_TEAMS = "teams/RECEIVE_TEAMS";
export const RESET_TEAMS = "teams/RESET_TEAMS";

export const RECEIVE_TEAM = "teams/RECEIVE_TEAM";
export const RESET_TEAM = "teams/RESET_TEAM";

export const REQUEST_TEAM_MEMBERS = "teams/REQUEST_TEAM_MEMBERS";
export const RECEIVE_TEAM_MEMBERS = "teams/RECEIVE_TEAM_MEMBERS";
export const RESET_TEAM_MEMBERS = "teams/RESET_TEAM_MEMBERS";
export const RELOAD_TEAM_MEMBERS = "teams/RELOAD_TEAM_MEMBERS";
export const GAME_INITIATORS_SAVED = "teams/GAME_INITIATORS_SAVED";

export const TEAM_IMAGE_CHANGED = "teams/TEAM_IMAGE_CHANGED";
export const UPLOAD_TEAM_IMAGE = "teams/UPLOAD_TEAM_IMAGE";
export const UPLOAD_TEAM_IMAGE_SUCCESS = "teams/UPLOAD_TEAM_IMAGE_SUCCESS";
export const UPLOAD_TEAM_IMAGE_ERROR = "teams/UPLOAD_TEAM_IMAGE_ERROR";

export const REQUEST_REVOKE_APPLICATION = "teams/requestRevokeApplication";
export const RECEIVE_REVOKE_APPLICATION = "teams/receiveRevokeApplication";
export const RESET_REVOKE_APPLICATION = "teams/resetRevokeApplication";

export const SET_UNREAD_COMMENTS = "teams/SET_UNREAD_COMMENTS";

export const TEAM_CREATED = "teams/TEAM_CREATED";

export type JoinTeamOptions = {
    teamId: number;
    teamName: string;
    isFull: boolean;
    isCampaignTeam: boolean;
};

export type SubmitApplyForTeamOptions = string;

export type TeamIdOptions = {
    teamId: number;
};

export type ApplyForTeamOptions = {
    teamId: number;
    teamName: string;
};

export type TeamSummaryPayload = {
    team: deprecated_MemberTeam;
};

export type PurchaseShareAction = {
    type: typeof PURCHASE_SHARE_ACTION;
    payload: TeamIdOptions;
};

export type WriteCommentAction = {
    type: typeof WRITE_COMMENT_ACTION;
    payload: TeamIdOptions;
};

export type FetchMyTeamsAction = {
    type: typeof REQUEST_TEAMS;
    payload: {
        refreshTeams?: boolean;
    };
};

export type SetMyTeamsAction = {
    type: typeof RECEIVE_TEAMS;
    payload: {
        teams: MemberTeam[];
    };
};

export type ResetMyTeamsAction = {
    type: typeof RESET_TEAMS;
};

export type SetUnreadCommentsAction = {
    type: typeof SET_UNREAD_COMMENTS;
    payload: {
        teamId: string;
        nrOfUnreadComments: number;
    };
};

export type SetTeamAction = {
    type: typeof RECEIVE_TEAM;
    payload: {teamId: string};
};

export type ResetTeamAction = {
    type: typeof RESET_TEAM;
};

export type ResetTeamMembersAction = {
    type: typeof RESET_TEAM_MEMBERS;
};

export type GetTeamMembersAction = FetchAction<
    typeof REQUEST_TEAM_MEMBERS,
    typeof RECEIVE_TEAM_MEMBERS,
    TeamMembers
>;

export type RevokeApplicationAction = FetchAction<
    typeof REQUEST_REVOKE_APPLICATION,
    typeof RECEIVE_REVOKE_APPLICATION,
    unknown
>;

export type ResetRevokeApplicationAction = {
    type: typeof RESET_REVOKE_APPLICATION;
};

export type GameInitiatorsSavedAction = {
    type: typeof GAME_INITIATORS_SAVED;
    payload: TeamIdOptions;
};

export type MemberJoinedTeamAction = {
    type: typeof TEAM_MEMBER_JOINED;
    payload: TeamSummaryPayload;
};

export type ReloadTeamMembersAction = {
    type: typeof RELOAD_TEAM_MEMBERS;
    payload: {
        teamId: string;
    };
};

export type TeamImageChangedAction = {
    type: typeof TEAM_IMAGE_CHANGED;
    payload: {
        imageUrl: ImageUrl;
    };
};

export type TeamCreatedAction = {
    type: typeof TEAM_CREATED;
    payload: {
        teamId: string;
    };
};

export type UploadTeamImageAction = {
    type: typeof UPLOAD_TEAM_IMAGE;
    payload: ImageFilePayload;
};

type UploadTeamImageSuccessAction = {
    type: typeof UPLOAD_TEAM_IMAGE_SUCCESS;
    error: false;
    payload: {
        imageUrl: ImageUrl;
    };
};

type UploadTeamImageErrorAction = {
    type: typeof UPLOAD_TEAM_IMAGE_ERROR;
    error: true;
    payload: {
        message: string;
        error?: Error;
    };
};

export type UploadTeamImageResponseAction =
    | UploadTeamImageSuccessAction
    | UploadTeamImageErrorAction;

export type TeamAction =
    | PurchaseShareAction
    | WriteCommentAction
    | FetchMyTeamsAction
    | SetMyTeamsAction
    | SetTeamAction
    | ResetTeamAction
    | ResetMyTeamsAction
    | MemberJoinedTeamAction
    | TeamImageChangedAction
    | GetTeamMembersAction
    | ReloadTeamMembersAction
    | UploadTeamImageAction
    | UploadTeamImageResponseAction
    | SetUnreadCommentsAction
    | TeamCreatedAction;

export const purchaseShare = (options: TeamIdOptions): PurchaseShareAction => ({
    type: PURCHASE_SHARE_ACTION,
    payload: options,
});

export const writeComment = (options: TeamIdOptions): WriteCommentAction => ({
    type: WRITE_COMMENT_ACTION,
    payload: options,
});

export const memberJoinedTeam = (
    payload: TeamSummaryPayload,
): MemberJoinedTeamAction => ({
    type: TEAM_MEMBER_JOINED,
    payload,
});

export const fetchMyTeams = (refreshTeams = false): FetchMyTeamsAction => ({
    type: REQUEST_TEAMS,
    payload: {
        refreshTeams,
    },
});

export const setMyTeams = (teams: MemberTeam[]): SetMyTeamsAction => ({
    type: RECEIVE_TEAMS,
    payload: {
        teams,
    },
});

export const resetMyTeams = (): ResetMyTeamsAction => ({
    type: RESET_TEAMS,
});

export const setTeamId = (teamId: string): SetTeamAction => ({
    type: RECEIVE_TEAM,
    payload: {teamId},
});

export const revokeApplication = (applicationId: number): RevokeApplicationAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_REVOKE_APPLICATION,
        receiveAction: RECEIVE_REVOKE_APPLICATION,
        callApi: call(teamApi.revokeApplication, applicationId),
    },
});

export const resetRevokeApplication = (): ResetRevokeApplicationAction => ({
    type: RESET_REVOKE_APPLICATION,
});

export const resetTeamMembers = (): ResetTeamMembersAction => ({
    type: RESET_TEAM_MEMBERS,
});

export const resetTeam = (): ResetTeamAction => ({
    type: RESET_TEAM,
});

export const uploadTeamImage = (file: File): UploadTeamImageAction => ({
    type: UPLOAD_TEAM_IMAGE,
    payload: {file},
});

/**
 * @deprecated
 */
export const getTeamMembers = (
    teamId: string,
    isLoggedOut?: boolean,
): GetTeamMembersAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_TEAM_MEMBERS,
        receiveAction: RECEIVE_TEAM_MEMBERS,
        callApi: call(teamApi.fetchTeamMembers, teamId, isLoggedOut),
        transformResponse: TeamApiUtils.deprecated_transformTeamMembersResponse,
    },
});

/**
 * @deprecated
 */
export const setUnreadComments = (
    teamId: string,
    nrOfUnreadComments: number,
): SetUnreadCommentsAction => ({
    type: SET_UNREAD_COMMENTS,
    payload: {teamId, nrOfUnreadComments},
});

/**
 * @deprecated
 */
export const updateTeamImage = (imageUrl: ImageUrl): TeamImageChangedAction => ({
    type: TEAM_IMAGE_CHANGED,
    payload: {imageUrl},
});

/**
 * @deprecated
 */
export const uploadTeamImageSuccess = (
    imageUrl: ImageUrl,
): UploadTeamImageSuccessAction => ({
    type: UPLOAD_TEAM_IMAGE_SUCCESS,
    error: false,
    payload: {imageUrl},
});

/**
 * @deprecated
 */
export const uploadTeamImageError = (
    message: string,
    error?: Error,
): UploadTeamImageErrorAction => ({
    type: UPLOAD_TEAM_IMAGE_ERROR,
    error: true,
    payload: {
        message,
        error,
    },
});

export const teamCreated = (teamId: string): TeamCreatedAction => ({
    type: TEAM_CREATED,
    payload: {
        teamId,
    },
});
