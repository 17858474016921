/* eslint-disable lingui/no-unlocalized-strings */
import {capitalize, find, remove} from "lodash";
import {gql} from "@apollo/client";
import type {
    Round,
    Sales,
    Participant,
    RoundState,
    Member,
} from "@atg-tillsammans/types/generated";
import {MemberModel, type TeamID} from "@atg-tillsammans/types";
import {getApolloClient} from "@atg-tillsammans/apollo-client";
import {
    RoundParticipantsDocument,
    TeamRoundBalanceDocument,
} from "@atg-tillsammans/graphql/query/__generated__/roundQueries.graphql.generated";
import {MyStartPageDocument} from "@atg-tillsammans/graphql/query/__generated__/pageQueries.graphql.generated";
import {
    TeamMembersDocument,
    type TeamMembersQuery,
} from "@atg-tillsammans/graphql/query/__generated__/memberQueries.graphql.generated";
import {CORE_MEMBER_FIELDS} from "@atg-tillsammans/graphql/fragment/memberFragments.graphql";

export const capitalized = (text?: string): string =>
    (text && text.split(" ").map(capitalize).join(" ")) || "";

export const chatNotificationAllowed = (
    currentTeamId: number,
    channelId: number,
    dialogOpen: boolean,
    smallScreen: boolean,
) => {
    const isStandingOnDifferentTeam = currentTeamId !== channelId;
    const isAllowedOnSameTeam = !dialogOpen && smallScreen;
    return isStandingOnDifferentTeam || isAllowedOnSameTeam;
};

export function writeSalesFragment(sales: Sales) {
    const client = getApolloClient();
    if (!client) return;
    client.cache.writeFragment({
        id: `Sales:${sales.id}`,
        fragment: gql`
            fragment PushedSales on Sales {
                id
                deadline
                price
                buyLimit
                buyLimitPerPerson
                totalSalesAmount
                refundAmount
                scheduledReleases
            }
        `,
        data: {
            __typename: "Sales",
            id: sales.id,
            deadline: sales.deadline,
            price: sales.price,
            buyLimit: sales.buyLimit,
            buyLimitPerPerson: sales.buyLimitPerPerson,
            totalSalesAmount: sales.totalSalesAmount,
            refundAmount: sales.refundAmount,
            scheduledReleases: sales.scheduledReleases ?? [],
        },
    });
}

export function writeRoundBalanceQuery(
    teamId: string,
    gameId: string,
    roundBalance: number,
) {
    const client = getApolloClient();
    if (!client) return;

    client.cache.writeQuery({
        query: TeamRoundBalanceDocument,
        data: {roundBalance},
        variables: {
            teamId,
            gameId,
        },
    });
}

export function writeRoundFragment(round: Round) {
    const client = getApolloClient();
    if (!client) return;

    if (round.sales) {
        writeSalesFragment(round.sales);
    }

    client.cache.writeFragment({
        id: `Round:${round.id}`,

        fragment: gql`
            fragment PushedRound on Round {
                state
                gameInitiator
                sales
            }
        `,
        data: {
            state: round.state,
            gameInitiator: {
                __ref: `Member:${round?.gameInitiator?.id}`,
            },
            sales: {
                __ref: `Sales:${round.id}`,
            },
        },
    });
}

export function writeRoundStateFragment(roundId: string, roundState: RoundState) {
    const client = getApolloClient();
    if (!client) return;
    client.cache.writeFragment({
        id: `Round:${roundId}`,
        fragment: gql`
            fragment PushedRound on Round {
                state
            }
        `,
        data: {
            state: roundState,
        },
    });
}

export function writeRoundParticipationsQuery(
    round: Round,
    subscriptions: Record<number, number>,
) {
    const client = getApolloClient();
    if (!client) return;

    client.cache.writeQuery({
        query: RoundParticipantsDocument,
        variables: {
            teamId: `${round.teamId}`,
            gameId: round.gameId,
        },
        data: {
            roundParticipants: Object.keys(subscriptions).map((memberId) => {
                const memberIdAsNumber = Number(memberId);

                // Get the right member to set on the participant obejct to be stored in cache
                const member = client.cache.readFragment({
                    id: `Member:${memberId}`,
                    fragment: CORE_MEMBER_FIELDS,
                });

                const sharePrice = round?.sales?.price ?? 0;
                const numberOfShares = subscriptions[memberIdAsNumber];

                return {
                    __typename: "Participant",
                    id: `${round.id}_${memberId}`,
                    teamId: Number(round.teamId),
                    gameId: round.gameId,
                    numberOfShares,
                    totalCost: sharePrice * numberOfShares,
                    member,
                };
            }),
        },
    });
}

export function updateTeamMembersQuery(incomingMember: Member, teamId: TeamID) {
    const client = getApolloClient();
    if (!client) return;

    client.cache.updateQuery<TeamMembersQuery>(
        {
            query: TeamMembersDocument,
            variables: {
                teamId: `${teamId}`,
            },
        },
        (data) => {
            const teamMembers = data?.teamMembers ?? [];
            const existingMember = find(
                teamMembers,
                (member) => member.memberId === incomingMember.memberId,
            );
            if (existingMember) {
                return {teamMembers};
            }

            return {
                teamMembers: [...teamMembers, MemberModel.toMemberGraph(incomingMember)],
            };
        },
    );
}

export function removeByUpdateTeamMembersQuery(removeMemberId: number, teamId: TeamID) {
    const client = getApolloClient();
    if (!client) return;
    client.cache.updateQuery<TeamMembersQuery>(
        {
            query: TeamMembersDocument,
            variables: {
                teamId: `${teamId}`,
            },
        },
        (data) => ({
            teamMembers: (data?.teamMembers ?? []).filter(
                (member: Member) => member.memberId !== removeMemberId,
            ),
        }),
    );
}

const resolvParticipation = (
    participation: Participant,
    currantParticipations: Participant[] = [],
): Participant[] => {
    const participations = [...currantParticipations];

    const existingParticipation = remove(
        participations,
        ({id}) => id === participation.id,
    );

    const updatedParticipation = (
        existingParticipation.length > 0
            ? {
                  ...existingParticipation[0],
                  numberOfShares:
                      (existingParticipation[0].numberOfShares ?? 0) +
                      (participation.numberOfShares ?? 0),
                  totalCost:
                      (existingParticipation[0].totalCost ?? 0) +
                      (participation.totalCost ?? 0),
              }
            : {
                  ...participation,
                  __typename: "Participant",
                  member: {
                      ...participation.member,
                      __typename: "Member",
                      id: `${participation.member.id}`,
                  },
              }
    ) as Participant;

    return [updatedParticipation, ...participations];
};

export function updateRoundParticipationsQuery(participation: Participant) {
    const client = getApolloClient();
    if (!client) return;
    client.cache.updateQuery(
        {
            query: RoundParticipantsDocument,
            variables: {
                teamId: `${participation.teamId}`,
                gameId: participation.gameId,
            },
        },
        (data) => ({
            ...data,
            roundParticipants: resolvParticipation(
                participation,
                data?.roundParticipants,
            ),
        }),
    );
}

export function updateMyStartPageParticipationsQuery(participation: Participant) {
    const client = getApolloClient();
    if (!client) return;
    client.cache.updateQuery(
        {
            query: MyStartPageDocument,
        },
        (data) => {
            if (data) {
                return {
                    ...data,
                    participations: resolvParticipation(
                        participation,
                        data?.participations,
                    ),
                };
            }
            return {
                participations: resolvParticipation(participation),
            };
        },
    );
}
