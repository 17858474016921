import {put, call, take, takeLatest, takeEvery, select} from "redux-saga/effects";
import type {EventChannel, SagaIterator} from "redux-saga";
import {eventChannel} from "redux-saga";
import {t} from "@lingui/macro";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {serverTime} from "@atg-shared/server-time";
import type {Match, MatchClock} from "@atg-sport-shared/big9-types/matchTypes";
import {MatchStatus, PeriodType} from "@atg-sport-shared/big9-types/matchTypes";
import type {SportGame} from "../sportGameTypes";
import type {
    SportGameMatchClockStartAction,
    SportGameMatchClockStopAction,
    SportGameFetchResponseAction,
} from "./sportGameActions";
import * as SportGameActions from "./sportGameActions";
import * as SportGameSelectors from "./sportGameSelectors";

dayjs.extend(duration);

const oneMinuteInMS = 60000;

const minuteTicker = (startMinute = 0) =>
    eventChannel((emitter) => {
        let minute = startMinute;

        const interval = setInterval(() => {
            minute += 1;
            emitter(minute);
        }, oneMinuteInMS);

        return () => {
            clearInterval(interval);
        };
    });

// eslint-disable-next-line require-yield
function* stop(
    {
        matchIdFromTimer,
        channel,
    }: {matchIdFromTimer: Match["id"]; channel: EventChannel<number>},
    {payload: {matchId}}: SportGameMatchClockStopAction,
) {
    if (matchId === matchIdFromTimer) {
        channel.close();
    }
}

function* clockSaga({
    payload: {matchId, minute: startMinute, timestamp},
}: SportGameMatchClockStartAction): SagaIterator {
    if (matchId) {
        const serverDiff = timestamp
            ? Math.round(
                  dayjs
                      .duration(dayjs(serverTime(false)).diff(dayjs(timestamp)))
                      .asMinutes(),
              )
            : 0;

        const minuteWithDiff = (startMinute ?? 0) + Math.max(serverDiff, 0);

        if (minuteWithDiff) {
            yield put(
                SportGameActions.updateMatchClock({matchId, minute: minuteWithDiff}),
            );
        }
        const channel = yield call(minuteTicker, minuteWithDiff);

        yield takeLatest(SportGameActions.SPORT_GAME_MATCH_CLOCK_STOP, stop, {
            matchIdFromTimer: matchId,
            channel,
        });

        try {
            while (true) {
                const minute = yield take(channel);
                yield put(SportGameActions.updateMatchClock({matchId, minute}));
            }
            // eslint-disable-next-line no-empty
        } finally {
        }
    }
}

function* startClocksForGame(game: SportGame) {
    const ongoingMatches = game.matches.filter(
        (match) => match.status === MatchStatus.ONGOING,
    );

    const matchClocks = (yield select(SportGameSelectors.getMatchClocks)) as Record<
        string,
        MatchClock
    >;

    const gotClockAlready = (match: Match) =>
        Object.keys(matchClocks).length > 0 &&
        matchClocks[match.id] &&
        Object.keys(matchClocks[match.id]).length > 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const match of ongoingMatches) {
        if (match.liveData?.period) {
            if (gotClockAlready(match)) {
                yield put(SportGameActions.stopMatchClock({matchId: match.id}));
            }
            if (match.liveData.period.type === PeriodType.PeriodStarted) {
                yield put(
                    SportGameActions.startMatchClock({
                        matchId: match.id,
                        minute: match.liveData.period.clockMinutes,
                        timestamp: match.liveData.period.timestamp,
                    }),
                );
            } else {
                yield put(
                    SportGameActions.addMatchClock({
                        matchId: match.id,
                        minute: match.liveData.period.clockMinutes,
                    }),
                );
            }
        } else {
            console.warn(t`Match(${match.number}) is ongoing, but is missing liveData!`);
        }
    }
}

function* startClocks({payload: {game}}: SportGameFetchResponseAction): SagaIterator {
    if (game) {
        yield call(startClocksForGame, game);
    }
}

export default function* matcheSaga(): SagaIterator {
    yield takeEvery(SportGameActions.SPORT_GAME_MATCH_CLOCK_START, clockSaga);
    yield takeLatest(SportGameActions.SPORT_GAME_FETCH_RESPONSE, startClocks);
}
