import dayjs from "dayjs";
import {pureFetch as fetch} from "@atg-shared/fetch";
import type {AtgResponse, FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {Offering} from "@atg-sport-shared/big9-types/offeringTypes";
import {OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";

export const REQUEST_SPORT_JACKPOT = "jackpot/REQUEST_SPORT_JACKPOT";
export const RECEIVE_SPORT_JACKPOT = "jackpot/RECEIVE_SPORT_JACKPOT";
export const RESET_JACKPOT = "jackpot/RESET_JACKPOT";

export type SportOfferingFetchAction = FetchAction<
    typeof REQUEST_SPORT_JACKPOT,
    typeof RECEIVE_SPORT_JACKPOT,
    ReadonlyArray<Offering>
>;

export type Action = SportOfferingFetchAction;

export const fetchSportJackpot = (): SportOfferingFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SPORT_JACKPOT,
        receiveAction: RECEIVE_SPORT_JACKPOT,
        callApi: call(
            (): Promise<AtgResponse<Array<Offering>>> =>
                fetch<Array<Offering>>(
                    `/services/sports-info-offering/api/v1/offering?status=${Object.values(
                        OfferingStatus,
                    ).join(",")}&fromDate=${dayjs()
                        .subtract(5, "weeks")
                        .format("YYYY-MM-DD")}`,
                ),
        ),
    },
});
